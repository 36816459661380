import React from 'react';
import Icon from 'scalexp/components/atoms/Icon';
import Typography from 'scalexp/components/atoms/Typography';
import Select from 'scalexp/components/molecules/Select';
import Tooltip from 'scalexp/components/molecules/Tooltip';
import { theme } from 'scalexp/theme';
import {
  MetricSchema2Aggregation,
  MetricSchema2Complex,
  MetricSchema2Impact,
} from 'scalexp/utils/metrics/metricSchema2';
import styled from 'styled-components';

import ReportEditorUpdateDecimals from '../ReportEditorSelectDecimals';

interface ReportEditorCustomRowConfigProps {
  complexMetricSchemaProperties: Pick<MetricSchema2Complex, 'dataType' | 'decimals' | 'impact' | 'aggregation'>;
  updateComplexMetricSchemaProperties: (
    complexMetricSchemaProperties: Partial<
      Pick<MetricSchema2Complex, 'dataType' | 'decimals' | 'impact' | 'aggregation'>
    >,
  ) => void;
}

const StyledContainer = styled.div`
  width: 360px;
  display: grid;
  grid-template-columns: 120px 200px;
  gap: ${({ theme }) => theme.spacing(4)};
  padding: ${({ theme }) => theme.spacing(4)};
`;

const ConfigIcon = styled(Icon)`
  transition: 0.3s;
  padding: 5px;
  max-width: 40px;

  &:hover {
    background-color: ${({ theme }) => theme.palette.white};
    border-radius: 8px;
  }
`;

const ReportEditorCustomRowConfig: React.FC<React.PropsWithChildren<ReportEditorCustomRowConfigProps>> = ({
  complexMetricSchemaProperties,
  updateComplexMetricSchemaProperties,
}) => {
  const { decimals, impact, aggregation } = complexMetricSchemaProperties;

  const impactOptions: {
    label: string;
    value: MetricSchema2Impact;
  }[] = [
    { label: 'Increase is negative', value: 'negative' },
    { label: 'Increase is neutral', value: 'neutral' },
    { label: 'Increase is positive', value: 'positive' },
  ];

  const aggregationOptions: {
    label: string;
    value: MetricSchema2Aggregation;
  }[] = [
    { label: 'Summation', value: 'sum' },
    { label: 'Last value', value: 'last' },
  ];

  return (
    <Tooltip
      content={
        <StyledContainer onMouseDown={e => e.stopPropagation()}>
          <Typography>Decimals</Typography>
          <ReportEditorUpdateDecimals
            type="input"
            decimals={decimals}
            handleUpdateDecimals={newDecimals => updateComplexMetricSchemaProperties({ decimals: newDecimals })}
          />
          <Typography>Variance</Typography>
          <Select
            data={impactOptions}
            value={impact}
            onChange={newImact => updateComplexMetricSchemaProperties({ impact: newImact })}
            placement="bottomStart"
            zIndex="highest"
          />
          <Typography>YTD Aggregation</Typography>
          <Select
            data={aggregationOptions}
            value={aggregation}
            onChange={newAggregation => updateComplexMetricSchemaProperties({ aggregation: newAggregation })}
            placement="bottomStart"
            zIndex="highest"
          />
        </StyledContainer>
      }
      placement="top"
      color="white"
      trigger="click"
    >
      <Tooltip content="Additional configuration options">
        <ConfigIcon clickable name="settings" size={6} color={theme.palette.granite} />
      </Tooltip>
    </Tooltip>
  );
};

export default ReportEditorCustomRowConfig;
