import dayjs, { QUnitType } from 'dayjs';
import { TimeChart, TimeSeries } from 'scalexp/features/chart/ChartCard/types';
import { CalculationRequestEntries, CalculationRequestEntry } from 'scalexp/utils/metrics/types';

import { useChartDataType } from '../../../components/contexts/NewEditChartContext/useChartDataType';
import { useOrganisationContext } from '../../../components/contexts/OrganisationContext';
import useCurrencySelection from '../../../components/molecules/CurrencySelect/useCurrencySelection';
import { useDateSelectionDate } from '../../../components/molecules/DateSelect/useDateSelection';
import { getFiscalQuarter } from '../../../utils/dates';
import { DateKeys, dateToRequest } from '../../../utils/metrics/calculations';
import { useMultipleMetricSchemaSeries } from '../../../utils/metrics/useMetricSchemaSeries';
import { getDateBounds } from '../ChartCard/helpers';
import useExpandedSeries from '../ChartCard/useExpandedSeries';
import { getXAxis, getYAxis } from './helpers';

export const THRESHOLD_FOR_SMALL_NUMBERS = 3;

const CURSOR_TEMPLATE = {
  lineY: {
    stroke: '#ff7f00',
    disabled: true,
  },
  lineX: {
    stroke: '#ff7f00',
    disabled: true,
  },
  type: 'XYCursor',
};

const LEGEND_TEMPLATE = {
  fontSize: 10,
  marginBottom: 20,
  position: 'bottom',
};
const XY_SERIES_TEMPLATE = {
  z_index: 1,
  stacked: false,
  tooltipText: '{valueY}',
  fill_to_use_when_negative: null,
  fill: undefined,
  type: 'COLUMN',
  dataFields: {
    valueY: '',
    dateX: 'date',
    categoryX: 'category',
  },
  tooltip: {
    fontSize: 12,
  },
  strokeWidth: 0,
  stroke: '#000000',
} as const;

export const useTimeChartConfig = (config: TimeChart) => {
  const {
    consolidation_type,
    default_budget_set_id,
    default_consolidated_budget_set_id,
    financial_year_start: financialYearStart,
    default_currency_code,
  } = useOrganisationContext();
  const defaultBudgetId = consolidation_type === 'PARENT' ? default_consolidated_budget_set_id : default_budget_set_id;
  const focalDate = useDateSelectionDate();
  const [currencyCode] = useCurrencySelection();
  const currency = currencyCode || default_currency_code;
  const { fiscalYear, fiscalQuarter } = getFiscalQuarter(focalDate, 0, financialYearStart);
  const chartData = config.chart_data;
  const period = chartData.period;
  const { getSeriesDataType } = useChartDataType(config);
  const series = chartData.series;
  const dateKeys = getDateBounds(series, period, focalDate, financialYearStart).sort();
  const budgetIds = series
    .map(series => {
      if (series.value_basis === 'BUDGET') {
        return (consolidation_type === 'PARENT' ? series.consolidated_budget : series.budget) || defaultBudgetId;
      }

      return false;
    })
    .filter(Boolean) as number[];
  const { status, value: expandedSeries = [] } = useExpandedSeries(series);
  const sortedExpandedSeries = (expandedSeries as TimeSeries[]).find(
    series => series.type === 'ColumnSeries' && series.stacked,
  )
    ? expandedSeries.sort((series1, series2) => series1.value_basis.localeCompare(series2.value_basis))
    : expandedSeries;
  const dateRequest = dateToRequest(
    dateKeys as DateKeys,
    budgetIds.length ? [...budgetIds] : [defaultBudgetId],
    currency,
  );
  const { status: seriesStatus, value: expandedMetricSchemaSeries } = useMultipleMetricSchemaSeries(
    sortedExpandedSeries.map(series => series.metric_schema),
    dateRequest,
    status === 'success',
  );

  if (status !== 'success' || seriesStatus !== 'success') {
    return {
      status: 'pending',
      value: null,
    };
  }

  const chartSeries = (sortedExpandedSeries as TimeSeries[]).map((series, index) => {
    const valueBasis = series.value_basis;
    return {
      ...XY_SERIES_TEMPLATE,
      name: series.name,
      y_axis_side: (series as TimeSeries).y_axis_side,
      show_numbers: series.show_numbers,
      dataType: getSeriesDataType(series),
      zIndex: series.order || index,
      fill_to_use_when_negative: series.color_negative,
      fill: series.color,
      stroke: series.color,
      type: series.type,
      bullets:
        series.type === 'LineSeries'
          ? [
              {
                type: 'CircleBullet',
                scale: 0.8,
                states: { hover: { properties: { scale: 1.35 } } },
              },
            ]
          : undefined,
      strokeWidth: series.type === 'LineSeries' ? 2 : 0,
      stacked: sortedExpandedSeries[index - 1]
        ? sortedExpandedSeries[index - 1].value_basis === valueBasis && series.type === 'ColumnSeries' && series.stacked
        : series.type === 'ColumnSeries' && series.stacked,
      dataFields: {
        ...XY_SERIES_TEMPLATE.dataFields,
        valueY: `series-${series.id}-${series.name}-value-${valueBasis}-${index}`,
      },
    };
  });

  let yAxisLeftMaxValue = 0;
  let yAxisLeftMinValue = Infinity;
  let yAxisRightMaxValue = 0;
  let yAxisRightMinValue = Infinity;
  const chartConfigData = dateKeys.map((dateKey, dateIndex) => {
    let valueDict = {
      category: dateKey.split('-').reverse().join(' '),
      date: dateKey,
    };
    expandedMetricSchemaSeries!.forEach((metricSeries, index) => {
      const series = sortedExpandedSeries[index] as TimeSeries | undefined;
      if (!series) {
        return;
      }

      // skip dates that are not on the specified period
      const before = series.offset - series.periods + 1;
      const after = series.offset || 0;
      let currentDate = dayjs(dateKey);
      let unitType = 'months';
      if (period === 'QUARTER' || period === 'QUARTER_TO_DATE') {
        unitType = 'Q';
        const quarterValue = parseInt(dateKey.split('-').reverse()[0].replace('Q', '').replace('QTD', ''));
        currentDate = dayjs(currentDate).quarter(quarterValue);
      } else if (period === 'YEAR_TO_DATE' || period === 'YEAR') {
        unitType = 'year';
      }

      if (
        currentDate.isBefore(focalDate.add(before, unitType as QUnitType), unitType as QUnitType) ||
        currentDate.isAfter(focalDate.add(after, unitType as QUnitType), unitType as QUnitType)
      ) {
        return;
      }

      const chartSeriesData = chartSeries[index];
      const valueBasis = series.value_basis;
      const budgetId =
        (consolidation_type === 'PARENT' ? series.consolidated_budget : series.budget) || defaultBudgetId;
      const valueKey = chartSeriesData.dataFields.valueY;
      let isPercentage;

      let valueAtDate = 0;

      let offset = 0;
      if (valueBasis === 'BUDGET') {
        offset = ((dateRequest as unknown) as CalculationRequestEntries).findIndex(
          (v: CalculationRequestEntry) => v.source === 'budget' && v.budget_id === budgetId,
        );
      }

      if (offset === -1) {
        valueAtDate = 0;
        isPercentage = metricSeries[dateIndex]?.data_type?.toLowerCase() === 'percentage';
      } else {
        valueAtDate = Number(metricSeries[dateIndex + offset]?.value || 0);
        isPercentage = metricSeries[dateIndex + offset]?.data_type?.toLowerCase() === 'percentage';
      }

      let value = series.invert_axis ? valueAtDate * -1 : valueAtDate;
      if (isPercentage) {
        value = Number(value * 100);
      }
      if (series.y_axis_side === 'LEFT') {
        if (value > yAxisLeftMaxValue) {
          yAxisLeftMaxValue = value;
        }
        if (value < yAxisLeftMinValue) {
          yAxisLeftMinValue = value;
        }
      } else {
        if (value > yAxisRightMaxValue) {
          yAxisRightMaxValue = value;
        }
        if (value < yAxisRightMinValue) {
          yAxisRightMinValue = value;
        }
      }
      const formattedValue = isPercentage
        ? value.toFixed(2)
        : Math.abs(value) < THRESHOLD_FOR_SMALL_NUMBERS && value !== 0
        ? value.toFixed(2)
        : Math.round(value);
      valueDict = {
        ...valueDict,
        [valueKey]: formattedValue,
      };
    });

    const { date, category, ...values } = valueDict;
    return { ...valueDict, isNull: Object.values(values).every(value => !value) };
  });

  const xAxis = getXAxis(chartData, focalDate, fiscalYear, fiscalQuarter, currency);

  const yAxis = getYAxis(
    chartData,
    focalDate,
    fiscalYear,
    fiscalQuarter,
    currency,
    config.chart_data.show_zero_left_y_axis && yAxisLeftMinValue >= 0
      ? 0
      : yAxisLeftMinValue >= 0
      ? yAxisLeftMinValue
      : undefined,
    yAxisLeftMaxValue < 0 ? 0 : undefined,
    config.chart_data.show_zero_right_y_axis && yAxisRightMinValue >= 0
      ? 0
      : yAxisRightMinValue >= 0
      ? yAxisRightMinValue
      : undefined,
    yAxisRightMaxValue < 0 ? 0 : undefined,
  );
  const yAxes = yAxis;
  const xAxes = [xAxis];
  return {
    status: 'success',
    value: {
      type: 'XYChart',
      legend: LEGEND_TEMPLATE,
      titles: [],
      series: chartSeries,
      data: chartConfigData,
      yAxes,
      xAxes,
      cursor: CURSOR_TEMPLATE,
      isEmpty: chartConfigData.every(data => data.isNull),
      scrollbarX: {
        marginTop: 0,
        paddingTop: 0,
      },
    },
  };
};
