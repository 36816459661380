import React from 'react';
import Loader from 'scalexp/components/atoms/Loader';
import { KPIChart as IKPIChart } from 'scalexp/features/chart/ChartCard/types';
import styled, { css } from 'styled-components';

import ChangeText from '../../../components/atoms/ChangeText';
import { StyledIcon } from '../../../components/atoms/Icon';
import Typography from '../../../components/atoms/Typography';
import Column from '../../../components/layout/Column';
import Row from '../../../components/layout/Row';
import LimitedTextWithTooltip, {
  StyledLimitedTextWithTooltip,
} from '../../../components/molecules/LimitedTextWithTooltip';
import useKPIConfig from './useKPIConfig';

interface KPIChartProps {
  config: IKPIChart;
  rightContent?: React.ReactNode;
}

const StyledKPIChart = styled.div`
  ${({ theme }) =>
    css`
      width: 100%;
      height: 100%;
      background: ${theme.palette.white};
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      box-shadow: ${theme.shadow.card};
      & > div:first-child {
        min-height: 60px;
        padding-left: ${theme.spacing(5)};
        padding-right: ${theme.spacing(2.5)};
      }
      ${StyledLimitedTextWithTooltip} + ${StyledIcon} {
        color: ${theme.palette.primary.lighter};
      }
      & > div:last-child {
        width: 100%;
        padding: ${theme.spacing(5)};
        padding-top: ${theme.spacing(4)};
      }
    `}

  span.material-icons {
    cursor: pointer;
  }
`;

const KPIChart: React.FC<KPIChartProps> = ({ config, rightContent }) => {
  const { status, value } = useKPIConfig(config);
  const versus = config.chart_data.versus;
  const period = config.chart_data.period;
  if (!value) {
    return (
      <StyledKPIChart>
        <Loader size="sm" center content="Loading..." />{' '}
      </StyledKPIChart>
    );
  }
  const budgetName = value.budgetName;
  return (
    <StyledKPIChart>
      {status !== 'success' ? (
        <Loader size="sm" center content="Loading..." />
      ) : (
        <>
          <Row hAlign="space-between" vAlign="center" width="100%">
            <Row spacing="tiny" hAlign="center" vAlign="center">
              <LimitedTextWithTooltip
                textProps={{
                  weight: 'semibold',
                }}
                text={config.name}
              />
            </Row>
            {rightContent}
          </Row>
          <Column hAlign="start" vAlign="center" spacing="small">
            <Typography size="h5" weight="semibold">
              {value.formattedValue}
              <Typography size="tiny" weight="medium">
                {` in ${value.dateString}`}
              </Typography>
            </Typography>
            <ChangeText
              value={value.changeValue}
              period={versus === 'BUDGET' ? budgetName : `previous ${period.toLowerCase()}`}
              impact={value.impact}
              formattedValue={value.formattedChangeValue}
            />
          </Column>
        </>
      )}
    </StyledKPIChart>
  );
};
export default KPIChart;
