import { MetricSchema2 } from 'scalexp/utils/metrics/metricSchema2';
import { v4 as uuidv4 } from 'uuid';

import {
  Chart,
  PieChart,
  TimeChart,
  TimeSeries,
  WaterfallChart,
  WaterfallSeries,
} from '../../../features/chart/ChartCard/types';

const DEFAULT_TIME_SERIES: TimeSeries = {
  id: '',
  name: 'Series 1',
  order: 1,
  color: '#6091e6',
  color_negative: '',
  value_basis: 'ACTUAL',
  type: 'ColumnSeries',
  metric_schema: {
    schemaType: 'simple',
    decimals: 0,
    nodes: [
      {
        data: {
          metricId: 'REVENUE_TOTAL',
          operator: 'native',
          isCashflow: false,
        },
        operator: 'add',
      },
    ],
  } as MetricSchema2,
  expand: false,
  stacked: false,
  invert_axis: false,
  periods: 13,
  offset: 0,
  show_numbers: false,
  y_axis_side: 'LEFT',
};

const DEFAULT_PIE_CHART_JSON: PieChart = {
  id: '',
  chart_data: {
    id: '',
    value_basis: 'ACTUAL',
    metric_schema: {
      nodes: [
        {
          data: {
            metricId: 'REVENUE_TOTAL',
            operator: 'native',
            isCashflow: false,
          },
          operator: 'add',
        },
      ],
      schemaType: 'simple',
      decimals: 0,
    } as MetricSchema2,
    color: '#6794dc',
    period: 'YEAR_TO_DATE',
    budget: null,
    show_numbers: false,
  },
  header_config: null,
  x: 0,
  y: 0,
  width: 2,
  height: 3,
  name: 'New Chart',
  currency: null,
  chart_type: 'pie_chart',
  created_at: '',
  updated_at: '',
};

const DEFAULT_TIME_CHART_JSON = ({
  name: 'New Chart',
  header_config: null,
  chart_type: 'time_chart',
  chart_data: {
    period: 'MONTH',
    x_axis_label: null,
    y_axis_label: '',
    x_axis_label_prepend: '',
    y_axis_label_prepend: 'CURRENCY',
    x_axis_label_append: '',
    y_axis_label_append: '',
    series: [
      {
        name: 'Total Revenue actuals',
        order: 1,
        color: '#6091e6',
        color_negative: null,
        value_basis: 'ACTUAL',
        type: 'ColumnSeries',
        metric_schema: {
          nodes: [
            {
              data: {
                metricId: 'REVENUE_TOTAL',
                operator: 'native',
                isCashflow: false,
              },
              operator: 'add',
            },
          ],
          schemaType: 'simple',
          decimals: 0,
        } as MetricSchema2,
        expand: false,
        stacked: true,
        invert_axis: false,
        periods: 13,
        offset: 0,
      },
    ],
  },
} as unknown) as Chart;

const DEFAULT_WATERFALL_SERIES = {
  id: '',
  name: 'Revenue',
  order: 1,
  color: '#65d25b',
  color_negative: '#6091e6',
  value_basis: 'ACTUAL',
  invert_axis: false,
  metric_schema: {
    nodes: [
      {
        data: {
          metricId: 'REVENUE_TOTAL',
          operator: 'native',
          isCashflow: false,
        },
        operator: 'add',
      },
    ],
    schemaType: 'simple',
    decimals: 0,
  } as MetricSchema2,
  budget: null,
  consolidated_budget: null,
};

const DEFAULT_WATERFALL_SERIES_2: WaterfallSeries = {
  id: '',
  name: 'Cost of Sales',
  order: 2,
  color: '#84c77e',
  color_negative: '#f5bcbc',
  value_basis: 'ACTUAL',
  invert_axis: true,
  metric_schema: {
    nodes: [
      {
        data: {
          metricId: 'COST_OF_SALES',
          operator: 'native',
          isCashflow: false,
        },
        operator: 'add',
      },
    ],
    schemaType: 'simple',
    decimals: 0,
  } as MetricSchema2,
  budget: null,
  consolidated_budget: null,
  show_numbers: false,
};

const DEFAULT_WATERFALL_CHART = {
  chart_data: {
    series: [],
    period: 'MONTH',
    x_axis_label: '',
    x_axis_label_prepend: '',
    x_axis_label_append: 'MONTH',
    y_axis_label: '',
    y_axis_label_prepend: '',
    y_axis_label_append: 'CURRENCY',
    number_of_periods: 1,
    offset: 0,
  },
  header_config: null,
  x: 0,
  y: 3,
  width: 2,
  height: 3,
  name: 'New Chart',
  currency: null,
  chart_type: 'waterfall_chart',
  created_at: '',
  updated_at: '',
};

export const getDefaultChart = (type: string) => {
  let newChart = { ...DEFAULT_TIME_CHART_JSON, id: '' };
  switch (type) {
    case 'bar':
      const barSeries = {
        ...DEFAULT_TIME_SERIES,
        id: uuidv4(),
      } as TimeSeries;
      (newChart as TimeChart).chart_data.series = [barSeries];
      break;
    case 'stacked':
      const stackedSeries = {
        ...DEFAULT_TIME_SERIES,
        stacked: true,
        id: uuidv4(),
      };
      const stackedSeries2 = {
        ...DEFAULT_TIME_SERIES,
        stacked: true,
        id: uuidv4(),
        color: '#84c77e',
        name: 'Series 2',
      };
      (newChart as TimeChart).chart_data.series = [stackedSeries, stackedSeries2];
      break;

    case 'pie':
      newChart = {
        ...DEFAULT_PIE_CHART_JSON,
        chart_data: { ...DEFAULT_PIE_CHART_JSON.chart_data, id: uuidv4() },
      };
      break;
    case 'donut':
      break;
    case 'line':
      const LineSeries = {
        ...DEFAULT_TIME_SERIES,
        y_axis_side: 'LEFT',
        type: 'LineSeries',
        id: uuidv4(),
      } as TimeSeries;
      (newChart as TimeChart).chart_data.series = [LineSeries];
      break;
    case 'dual':
      const dualSeries = [
        {
          ...DEFAULT_TIME_SERIES,
          type: 'ColumnSeries',
          id: uuidv4(),
        },
        {
          ...DEFAULT_TIME_SERIES,
          name: 'Series 2',
          type: 'LineSeries',
          y_axis_side: 'RIGHT',
          color: '#4B5D78',
          metric_schema: {
            operator: 'add',
            nodes: [
              {
                operator: 'add',
                data: {
                  operator: 'derived',
                  metricId: 'NET_REVENUE_RETENTION',
                },
              },
            ],
          },
          id: uuidv4(),
        },
      ] as TimeSeries[];
      (newChart as TimeChart).chart_data.series = dualSeries;
      break;
    case 'waterfall':
      newChart = {
        ...DEFAULT_WATERFALL_CHART,
        id: '',
        chart_data: {
          ...DEFAULT_WATERFALL_CHART.chart_data,
          id: uuidv4(),
          series: [
            { ...DEFAULT_WATERFALL_SERIES, id: uuidv4() },
            { ...DEFAULT_WATERFALL_SERIES_2, id: uuidv4() },
          ],
        },
      } as WaterfallChart;
      break;

    default:
      break;
  }
  return newChart;
};
