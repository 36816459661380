import React from 'react';
import Typography from 'scalexp/components/atoms/Typography';
import Column from 'scalexp/components/layout/Column';
import useGetTrackingCodesLabel from 'scalexp/components/molecules/QuickReportPAndLTrackingCodesOptions/useGetTrackingCodesLabel';
import SelectTrackingCategory from 'scalexp/components/molecules/SelectTrackingCategory';
import { MetricSchema2Window } from 'scalexp/utils/metrics/metricSchema2';
import styled from 'styled-components';

import Icon from '../../../../components/atoms/Icon';
import Tooltip from '../../../../components/molecules/Tooltip';
import { theme } from '../../../../theme';
import ReportEditorCashflowSelect from '../ReportEditorCashflowSelect';
import ReportEditorUpdateWindow from '../ReportEditorUpdateWindow';

interface ReportEditorRowConfigProps {
  isCashflow?: boolean;
  handleRevenueTypeChange?: (isCashflow: boolean) => void;
  window?: MetricSchema2Window;
  handleUpdateWindow: (window?: MetricSchema2Window) => void;
  trackingCategoryOptions?: string[];
  handleTrackingCategoryOptionsChange?: (value?: string[]) => void;
}

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(4)};
  padding: ${({ theme }) => theme.spacing(4)};
`;

const ConfigIcon = styled(Icon)`
  transition: 0.3s;
  padding: 5px;
  max-width: 30px;

  &:hover {
    background-color: ${({ theme }) => theme.palette.white};
    border-radius: 8px;
  }
`;

const ReportEditorRowConfig: React.FC<React.PropsWithChildren<ReportEditorRowConfigProps>> = ({
  isCashflow,
  handleRevenueTypeChange,
  window,
  handleUpdateWindow,
  trackingCategoryOptions,
  handleTrackingCategoryOptionsChange,
}) => {
  const after = window?.after ?? 0;
  // we say there isn't a window of before === -after, or the window is undefined
  const isValuePeriod = window?.before === -after || !window;
  const hasWindow = !isValuePeriod || (isValuePeriod && window && window.before !== 0);
  const hasTrackingCategory = trackingCategoryOptions && trackingCategoryOptions.length > 0;
  const trackingCodeLabel = useGetTrackingCodesLabel({ format: 'lower_case', singular: false });
  const trackingKey = trackingCodeLabel.split(' ')[0];

  const hasChanges = hasWindow || hasTrackingCategory;

  return (
    <Tooltip
      content={
        <StyledContainer>
          {isCashflow !== undefined && handleRevenueTypeChange && (
            <ReportEditorCashflowSelect
              value={isCashflow ? 'CASHFLOW' : 'ACTUALS'}
              onChange={value => {
                handleRevenueTypeChange(value === 'CASHFLOW');
              }}
            />
          )}
          {handleTrackingCategoryOptionsChange && (
            <Column onMouseDown={e => e.stopPropagation()}>
              <SelectTrackingCategory
                selectedTrackingCategoryOptions={trackingCategoryOptions || []}
                setSelectedTrackingCategoryOptions={handleTrackingCategoryOptionsChange}
                note={`Note: you can't select ${trackingKey} categories and cashflow at the same time`}
              />
            </Column>
          )}
          <Column spacing="tiny">
            <Typography color="secondary" size="small">
              Time Period
            </Typography>
            <ReportEditorUpdateWindow window={window} handleUpdateWindow={handleUpdateWindow} />
          </Column>
        </StyledContainer>
      }
      placement="top"
      color="white"
      trigger="click"
    >
      <Tooltip content="Additional configuration options">
        <ConfigIcon
          clickable={!hasChanges}
          name="settings"
          size={6}
          color={hasChanges ? theme.palette.primary.light : theme.palette.granite}
        />
      </Tooltip>
    </Tooltip>
  );
};

export default ReportEditorRowConfig;
