import { useMemo } from 'react';
import { MetricSchema2DataSource } from 'scalexp/utils/metrics/metricSchema2';
import { showNativeMetric, sortAccounts } from 'scalexp/utils/metrics/utils';

import useActiveOrganisation from '../../../components/contexts/OrganisationContext/useActiveOrganisation';
import useActiveOrganisationId from '../../../components/contexts/OrganisationContext/useActiveOrganisationId';
import {
  NATIVE_METRIC_IDS,
  INVOICED_REVENUE_METRIC_DISPLAY_NAMES,
  INVOICED_REVENUE_METRIC_IDS,
  SALES_METRIC_DATA_TYPE,
  SALES_METRIC_DISPLAY_NAMES,
} from '../../../service/types/metricSchema';
import { useAccountGroups } from '../../../store/state/accountGroups/hooks';
import { AccountGroup } from '../../../store/state/accountGroups/types';
import { getAccountDisplayName, useAccounts } from '../../../store/state/accounts/hooks';
import { Account } from '../../../store/state/accounts/types';
import { useDerivedMetrics } from '../../../store/state/derivedMetrics/hooks';
import { useNativeMetricNames } from '../../../store/state/nativeMetrics/hooks';
import { usePipelines } from '../../../store/state/pipelines/hooks';
import { MetricRow, Report, Row } from '../../../store/state/reports/types';

export type PickerOption = {
  name: string;
  id: string;
  role: string;
  source_id: string;
};

export const CUSTOM_NATIVE_METRICS = [
  'CUSTOM_NATIVE_METRIC_INTEGER_ONE',
  'CUSTOM_NATIVE_METRIC_INTEGER_TWO',
  'CUSTOM_NATIVE_METRIC_INTEGER_THREE',
  'CUSTOM_NATIVE_METRIC_DECIMAL_ONE',
  'CUSTOM_NATIVE_METRIC_DECIMAL_TWO',
  'CUSTOM_NATIVE_METRIC_DECIMAL_THREE',
  'CUSTOM_NATIVE_METRIC_PERCENTAGE_ONE',
  'CUSTOM_NATIVE_METRIC_PERCENTAGE_TWO',
  'CUSTOM_NATIVE_METRIC_PERCENTAGE_THREE',
  'CUSTOM_NATIVE_METRIC_MONETARY_ONE',
  'CUSTOM_NATIVE_METRIC_MONETARY_TWO',
  'CUSTOM_NATIVE_METRIC_MONETARY_THREE',
  'UNITS',
  'ORDERS',
  'MONTHS_OF_CASH',
];

export const useGetRowName = () => {
  const organisationId = useActiveOrganisationId();
  const accountsVS = useAccounts(organisationId);
  const groupsVS = useAccountGroups();
  const pipelinesVS = usePipelines(organisationId);
  const derivedMetricsVS = useDerivedMetrics(organisationId);
  const nativeMetricNamesVS = useNativeMetricNames();

  const getRowName = (row: MetricRow, rows: Row[] | undefined = []) => {
    let rowName = row.name;

    if (row.metric.schemaType === 'complex') {
      return rowName;
    }

    const data: MetricSchema2DataSource = row.metric.nodes[0].data;

    switch (data.operator) {
      case 'derived':
        rowName = derivedMetricsVS.value?.[data.metricId].name;
        break;
      case 'native':
        rowName = nativeMetricNamesVS.value?.[data.metricId];
        break;
      case 'sales':
        rowName = SALES_METRIC_DISPLAY_NAMES[data.metricId];
        break;
      case 'invoiced-revenue':
        rowName = INVOICED_REVENUE_METRIC_DISPLAY_NAMES[data.metricId];
        break;
      case 'entity':
        switch (data.entity) {
          case 'account':
            rowName = accountsVS.value?.[data.accountId]?.name;
            break;
          case 'group':
            rowName = groupsVS.value?.[data.groupId]?.name;
            break;
          case 'pipeline':
            rowName = (pipelinesVS.value || []).find(p => p.id === data.pipelineId)?.name;
            break;
          case 'stage':
            const pipeline = (pipelinesVS.value || []).find(p => p.id === data.pipelineId);
            rowName = (pipeline?.stages || []).find(s => s.id === data.stageId)?.name;
            break;
        }
        break;

      case 'constant':
        rowName = `Fixed Number ${data.value}`;
        break;
      case 'reference':
        const referencedRow = rows.find(row => row.id === data.rowId) as MetricRow;
        if (!referencedRow) {
          rowName = `removed reference row`;
          break;
        }
        rowName = `reference ${getRowName(referencedRow)}`;
        break;
    }

    return rowName;
  };

  return getRowName;
};

function usePickerOptions(config?: Report, showTwoStepPipelineOptions?: boolean) {
  const organisationId = useActiveOrganisationId();
  const organisation = useActiveOrganisation();
  const accountsVS = useAccounts(organisationId);
  const groupsVS = useAccountGroups();
  const pipelinesVS = usePipelines(organisationId);
  const derivedMetricsVS = useDerivedMetrics(organisationId);
  const nativeMetricNamesVS = useNativeMetricNames();

  const getRowName = useGetRowName();

  return useMemo(() => {
    if (!nativeMetricNamesVS.value) {
      return [];
    }

    const accountOptions: PickerOption[] = sortAccounts(Object.values(accountsVS.value || {}), organisation)
      .filter(account => !account.source_id.startsWith('net-revenue'))
      .map((account: Account) => ({
        name: getAccountDisplayName(account, organisation),
        id: `account:${account.account_id}`,
        role: account.source_name === 'manual' ? 'MANUAL DATA' : 'Account',
        source_id: account.source_name === 'manual' ? 'scalexp' : account.source_name ?? 'scalexp',
      }));

    const groupOptions: PickerOption[] = Object.values(groupsVS.value || {})
      .map((group: AccountGroup) => ({
        name: group.name,
        id: `group:${group.id}`,
        role: !!group.parent_group_id ? 'FINANCIAL SUBGROUP' : 'FINANCIAL GROUP',
        source_id: 'financial',
      }))
      .sort(group => (group.name === 'FINANCIAL GROUP' ? 1 : -1));

    const nativeMetricOptions: PickerOption[] = NATIVE_METRIC_IDS.filter(nativeMetricId =>
      showNativeMetric(nativeMetricId, nativeMetricNamesVS.value![nativeMetricId]),
    )
      .sort((a, b) => a.localeCompare(b))
      .map(nativeMetricId => ({
        name: (nativeMetricNamesVS.status === 'success' && nativeMetricNamesVS.value[nativeMetricId]) || nativeMetricId,
        id: `nm:${nativeMetricId}`,
        role: CUSTOM_NATIVE_METRICS.includes(nativeMetricId) ? 'MANUAL DATA' : 'FINANCIAL CATEGORY',
        source_id: CUSTOM_NATIVE_METRICS.includes(nativeMetricId) ? 'scalexp' : 'financial',
      }));

    const revenueMetricOptions: PickerOption[] = INVOICED_REVENUE_METRIC_IDS.sort((a, b) => {
      if (a === 'MRR_CUMULATIVE' || b === 'MRR_CUMULATIVE') {
        return 1;
      }
      return a.localeCompare(b);
    }).map(nativeMetricId => ({
      name: INVOICED_REVENUE_METRIC_DISPLAY_NAMES[nativeMetricId],
      id: `ir:${nativeMetricId}`,
      role: 'CUSTOMER PAGE DATA',
      source_id: 'scalexp',
    }));

    const derivedMetricOptions: PickerOption[] = Object.values(derivedMetricsVS?.value || {})
      .sort((a, b) => a.name.localeCompare(b.name))
      .filter(derivedMetric => !derivedMetric.isArchived)
      .map(derivedMetric => ({
        name: derivedMetric.name,
        id: `dm:${derivedMetric.metricId}`,
        role: 'DEFINED METRIC',
        source_id: 'scalexp',
      }));

    const salesMetricsOptions: PickerOption[] = Object.keys(SALES_METRIC_DATA_TYPE)
      .sort((a, b) => a.localeCompare(b))
      .map(salesMetricId => ({
        name: SALES_METRIC_DISPLAY_NAMES[salesMetricId] || salesMetricId,
        id: `sm:${salesMetricId}`,
        role: 'Sales Category',
        source_id: 'crm',
      }));

    const pipelineTypeOptionsList = [
      { name: 'Renewals', value: 'RENEWALS' },
      { name: 'New Sales', value: 'NEW_SALES' },
    ].map(pipelineType => {
      return {
        name: pipelineType.name,
        id: `pt:${pipelineType.value}`,
        role: 'Pipeline Type',
        source_id: 'scalexp',
      };
    });

    const pipelineOptions: PickerOption[] = Object.values(pipelinesVS.value || {})
      .sort((a, b) => a.name.localeCompare(b.name))
      .filter(pipeline => pipeline.category === 'NEW_SALES' || pipeline.category === 'RENEWALS')
      .flatMap(pipeline => [
        {
          name: pipeline.name,
          id: `pm:${pipeline.id}`,
          role: 'Pipeline',
          source_id: pipeline.source_name,
        },
        ...pipeline.stages.map(stage => ({
          name: `${pipeline.name} - ${stage.name}`,
          id: `ps:${stage.id}:${pipeline.id}`,
          role: 'Stage',
          source_id: pipeline.source_name,
        })),
      ]);

    const configRowOptions =
      config?.rows
        .filter(row => row.type !== 'EMPTY')
        .map(row => {
          const metricRow = row as MetricRow;
          let rowName = row.name;

          if (metricRow.metric.nodes.length === 1) {
            rowName = getRowName(metricRow, config?.rows);
          }

          return {
            name: `${row.name || rowName} (row)`,
            id: `row:${row.id}`,
            role: 'Row',
            source_id: 'row',
          };
        }) ?? [];

    const CRMDataOptions =
      organisation.consolidation_type === 'SUBSIDIARY'
        ? []
        : showTwoStepPipelineOptions
        ? [...pipelineTypeOptionsList, ...pipelineOptions]
        : [...salesMetricsOptions, ...pipelineOptions];

    const pickerOptions: PickerOption[] = [
      {
        name: '+ Number',
        id: 'constant',
        role: 'Number',
        source_id: 'scalexp',
      },
      ...accountOptions,
      ...CRMDataOptions,
      ...nativeMetricOptions,
      ...revenueMetricOptions,
      ...derivedMetricOptions,
      ...groupOptions,
      ...configRowOptions,
    ];

    return pickerOptions;
  }, [
    nativeMetricNamesVS.value,
    organisation.children,
    accountsVS.value,
    groupsVS.value,
    NATIVE_METRIC_IDS,
    CUSTOM_NATIVE_METRICS,
    derivedMetricsVS.value,
    SALES_METRIC_DATA_TYPE,
    SALES_METRIC_DISPLAY_NAMES,
    pipelinesVS.value,
    config,
  ]);
}

export default usePickerOptions;
