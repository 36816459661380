import React, { useState } from 'react';
import styled from 'styled-components';

import Typography from '../../../components/atoms/Typography';
import Column from '../../../components/layout/Column';
import Card from '../../../components/molecules/Card';
import Select from '../../../components/molecules/Select';
import useUpdateUserSettings from '../../../pages/settings/general/useUpdateUserSettings';
import { UserProfile } from '../../../pages/settings/general/useUserSettings';

interface DateFormatProps {
  format: UserProfile['date_format'];
}

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(2)};
  padding: ${({ theme }) => `${theme.spacing(2)} ${theme.spacing(6)} ${theme.spacing(5)} ${theme.spacing(6)}`};

  &:first-of-type {
    padding-top: ${({ theme }) => theme.spacing(5)};
  }
`;

const DateFormat: React.FC<React.PropsWithChildren<DateFormatProps>> = ({ format }) => {
  const updateUserSettings = useUpdateUserSettings();

  const [selectedFormat, setSelectedFormat] = useState<UserProfile['date_format']>(format);

  const dateFormatOptions = [
    { label: 'DD-MM-YYYY', value: 'DMY' },
    { label: 'MM-DD-YYYY', value: 'MDY' },
    { label: 'YYYY-MM-DD', value: 'YMD' },
  ] as { label: string; value: UserProfile['date_format'] }[];

  const handleUpdateFormat = (dateFormat: UserProfile['date_format']) => {
    setSelectedFormat(dateFormat);
    updateUserSettings({ date_format: dateFormat });
  };

  return (
    <Card width="760px">
      <StyledContainer>
        <Column width="100%">
          <Typography>Choose the date format you prefer to see across the ScaleXP system</Typography>
          <Select width="150px" value={selectedFormat} data={dateFormatOptions} onChange={handleUpdateFormat} />
        </Column>
      </StyledContainer>
    </Card>
  );
};

export default DateFormat;
