import { useAuth0 } from '@auth0/auth0-react';
import { GetTokenSilentlyVerboseResponse } from '@auth0/auth0-spa-js';
import React from 'react';

import { useSelf } from '../../../service';
import { setAccessToken } from '../../../utils/accessToken';
import { setCurrentOrganisation } from '../../../utils/organisation';

const SwitchOrganisationHandler = () => {
  const { isLoading, getAccessTokenSilently, error, loginWithRedirect } = useAuth0();
  const params = new URLSearchParams(window.location.search);
  // the org_id query param is used to identify the organisation the user is switching to
  const organization_auth0_id = params.get('org_id');
  const { data: user } = useSelf();
  // when auth0 redirects to this page, it will have a redirect query param
  const redirect = params.get('redirect');
  // the next query param is used to redirect to the correct page after the user has switched orgs
  const nextStep = params.get('next');

  if (isLoading) {
    return null;
  }

  if (redirect) {
    // the user has been redirected from auth0, so we need to set the access token and redirect to the correct page
    getAccessTokenSilently({ detailedResponse: true }).then((token: GetTokenSilentlyVerboseResponse) => {
      setAccessToken(token.access_token);
      switch (nextStep) {
        case 'connections':
          window.location.href = '/oauth/';
          break;
        case 'crm':
          window.location.href = '/oauth?onboarding=crm';
          break;
        case 'stripe':
          window.location.href = '/oauth?onboarding=stripe';
          break;
        case 'suppliers':
          window.location.href = '/journals/suppliers/identify';
          break;
        case 'net-revenue':
          window.location.href = '/analysis/net-revenue/';
          break;

        default:
          window.location.href = '/';
          break;
      }
    });
    return null;
  }

  const availableOrganisations =
    user?.available_organisations
      ?.sort((o1, o2) => o1[0].name.localeCompare(o2[0].name))
      ?.flatMap(orgList => {
        return orgList.map((org, index) => {
          const consolidatedChild = index !== 0 && orgList.length > 1;
          const isFirstAndConsolidated = index === 0 && orgList.length > 1;
          return {
            ...org,
            consolidated: isFirstAndConsolidated,
            child: consolidatedChild,
          };
        });
      }) ?? [];

  const redirectConfig = {
    organization: organization_auth0_id,
    redirectUri: `${window.location.origin}/switch-organisation-handler?org_id=${organization_auth0_id}&redirect=1&next=${nextStep}`,
    appState: {
      redirectUri: `${window.location.origin}/switch-organisation-handler?org_id=${organization_auth0_id}&redirect=1&next=${nextStep}`,
    },
  };

  if (error) {
    console.log('error in auth0 hook-', error);
  }

  const org = availableOrganisations.find(org => org.auth0_id === organization_auth0_id);

  if (!org) {
    return <p>invalid organisation!</p>;
  }

  setCurrentOrganisation(org);

  // @ts-ignore
  loginWithRedirect(redirectConfig);

  return null;
};

export default SwitchOrganisationHandler;
