import { useAuth0 } from '@auth0/auth0-react';
import { useCallback } from 'react';
import { clearSession } from 'scalexp/utils/auth';
import fetcher from 'scalexp/utils/fetcher';

const useLogoutAction = () => {
  const { logout } = useAuth0();

  return useCallback(async () => {
    await fetcher('/logout/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });
    clearSession();
    logout();
  }, [logout]);
};

export default useLogoutAction;
