import React, { useState } from 'react';
import Tooltip from 'scalexp/components/molecules/Tooltip';
import { FormulaPickerOption } from 'scalexp/features/report-editor/ReportEditorAddRowSelect/helpers';
import styled from 'styled-components';

import Typography from '../../../components/atoms/Typography';
import Row from '../../../components/layout/Row';
import ReportEditorAddRowSelect from '../../report-editor/ReportEditorAddRowSelect';

export interface FormulaEditorAddRowAndBracketsProps {
  pickerOptions: FormulaPickerOption[];
  handleAddRows: (values: string[]) => void;
  handleAddBrackets?: () => void;
  includeRows?: boolean;
  includeBrackets?: boolean;
  showBracketsButton?: boolean;
}

const StyledContainer = styled.div<{ showAddBrackets: boolean }>`
  width: 100%;
  height: 54px;
  display: grid;
  grid-template-columns: ${({ showAddBrackets }) => `1fr ${showAddBrackets ? '90px' : ''}`};
  gap: ${({ theme }) => theme.spacing(4)};

  & > div {
    background-color: ${({ theme }) => theme.palette.white};
    border-radius: 10px;
  }
  & > div:first-child {
    padding: ${({ theme }) => `0 ${theme.spacing(8)}`};
  }
  & > div:nth-child(2) {
    cursor: pointer;
  }
`;

const BracketsIcon = styled(Typography)`
  transition: 0.3s;
`;

const BracketsIconContainer = styled(Row)`
  transition: 0.3s;
  height: 100%;
  &:hover span:last-child {
    color: ${({ theme }) => theme.palette.primary.light};
  }
`;
const FormulaEditorAddRowAndBrackets: React.FC<FormulaEditorAddRowAndBracketsProps> = ({
  pickerOptions,
  handleAddRows,
  handleAddBrackets,
  includeRows,
  includeBrackets,
  showBracketsButton = true,
}) => {
  const [showDropDown, setShowDropDown] = useState(false);

  return (
    <StyledContainer showAddBrackets={Boolean(handleAddBrackets && showBracketsButton)}>
      <Row width="100%" vAlign="center">
        <ReportEditorAddRowSelect
          showDropDown={showDropDown}
          setShowDropDown={setShowDropDown}
          data={pickerOptions}
          handleAddRows={handleAddRows}
          includeFormula={false}
          includeRows={includeRows}
          includeBrackets={includeBrackets}
        />
      </Row>
      {handleAddBrackets && showBracketsButton && (
        <Tooltip content="Add formula with brackets or parenthesis.">
          <BracketsIconContainer hAlign="center" vAlign="center" onClick={handleAddBrackets} width="100%">
            <BracketsIcon size="medium">()</BracketsIcon>
          </BracketsIconContainer>
        </Tooltip>
      )}
    </StyledContainer>
  );
};
export default FormulaEditorAddRowAndBrackets;
