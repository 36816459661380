import React, { PropsWithChildren, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Notification } from 'rsuite';
import Loader from 'scalexp/components/atoms/Loader';
import { useQueryParam } from 'use-query-params';

import { Chart, TimeSeries, WaterfallSeries } from '../../../features/chart/ChartCard/types';
import { useCreateChart, useCreateDashboard, useDashboards } from '../../../store/state/newDashboards/hooks';
import { DashboardChartCard } from '../../../store/state/newDashboards/types';
import fetcher from '../../../utils/fetcher';
import { useChartTemplates } from '../../molecules/DashboardChartPlaceholder';
import useActiveOrganisationId from '../OrganisationContext/useActiveOrganisationId';

export interface DashboardEditorContextProps {}

export const DashboardEditorContext = React.createContext<{
  isPinned: boolean;
  dashboardName: string;
  charts: PreviewChartConfig[];
  toggleEnabled: (index: number) => void;
  saveDashboard: () => Promise<void>;
  setIsPinned: (isPinned: boolean) => void;
  setDashboardName: (name: string) => void;
  primaryColour: string;
  setPrimaryColour: (colour: string) => void;
  secondaryColour: string;
  setSecondaryColour: (colour: string) => void;
  targetsColour: string;
  setTargetsColour: (colour: string) => void;
}>(undefined!);

export type PreviewChartConfig = {
  enabled: boolean;
  x: number;
  y: number;
  h: number;
  w: number;
  name: string | undefined;
  id: number | undefined;
  chart_data: Chart | undefined;
};

function stackCharts(gridWidth: number, objWidth: number, charts: PreviewChartConfig[]): PreviewChartConfig[] {
  let numPerRow = Math.floor(gridWidth / objWidth);

  let arrangedObjects: PreviewChartConfig[] = [];

  let currentY = 0;
  let maxHeightInRow = 0;

  charts.forEach((obj, index) => {
    let x = index % numPerRow;
    if (x === 0 && index !== 0) {
      // start of a new row (but not the first row)
      currentY += maxHeightInRow;
      maxHeightInRow = 0; // reset the max height for the new row
    }

    obj.x = x * objWidth;
    obj.y = currentY;

    if (obj.h > maxHeightInRow) {
      // update max height if this object is taller
      maxHeightInRow = obj.h;
    }

    arrangedObjects.push(obj);
  });

  return arrangedObjects;
}

const DEFAULT_CHART_COORDINATES = {
  x: 0,
  y: 0,
  h: 3,
  w: 2,
};
function getColoredWaterfallSeries(series: WaterfallSeries[], primaryColor: string, targetsColor: string) {
  const actualSeries = series.filter(s => {
    return s.value_basis === 'ACTUAL';
  });
  const actualColoredSeries = actualSeries.map((series, index) => {
    const color = index === 0 ? primaryColor : index === actualSeries.length - 1 ? primaryColor : series.color;
    return {
      ...series,
      color,
    };
  });

  const targetsSeries = series
    .filter(s => {
      return s.value_basis === 'BUDGET';
    })
    .map(s => {
      return {
        ...s,
        color: targetsColor,
      };
    });

  return [...actualColoredSeries, ...targetsSeries];
}
function getColoredTimeSeries(
  series: TimeSeries[],
  primaryColor: string,
  secondaryColor: string,
  targetsColor: string,
) {
  const actualSeries = series.filter(s => {
    return s.value_basis === 'ACTUAL';
  });
  const actualColoredSeries = actualSeries.map((series, index) => {
    const color = index === 0 ? primaryColor : index === 1 ? secondaryColor : series.color;
    return {
      ...series,
      color,
    };
  });

  const targetsSeries = series
    .filter(s => {
      return s.value_basis === 'BUDGET';
    })
    .map(s => {
      return {
        ...s,
        color: targetsColor,
      };
    });

  return [...actualColoredSeries, ...targetsSeries];
}

export const DashboardEditorProvider: React.FC<PropsWithChildren<DashboardEditorContextProps>> = ({ children }) => {
  const [chartIdsString = ''] = useQueryParam<string>('chartIds');
  const organisationId = useActiveOrganisationId();
  const dashboardVS = useDashboards(organisationId);

  const chartIds = chartIdsString
    .split(',')
    .filter(Boolean)
    .map(id => {
      const chartId = parseInt(id);
      return { id: chartId, enabled: true };
    });

  const { chartConfigs, isValidating } = useChartTemplates();
  const history = useHistory();
  const [primaryColour, setPrimaryColour] = useState<string>('#6091e6');
  const [secondaryColour, setSecondaryColour] = useState<string>('#000000');
  const [targetsColour, setTargetsColour] = useState<string>('#fb6065');
  const [dashboardName, setDashboardName] = useState<string>('New Dashboard');
  const [isPinned, setIsPinned] = useState<boolean>(false);
  const [charts, setCharts] = useState<
    {
      id: number;
      enabled: boolean;
    }[]
  >(chartIds);
  const createDashboard = useCreateDashboard(organisationId);
  const createChart = useCreateChart(organisationId);

  if (isValidating || !chartConfigs || !chartIdsString) {
    return <Loader content="Loading..." center vertical />;
  }

  const chartsWithConfig = charts
    .filter(chart => chartConfigs.charts.find(config => config.id === chart.id))
    .map(chart => {
      const chartConfig = chartConfigs.charts.find(config => config.id === chart.id)!;

      const disableBrandColours = chartConfig.template_json.disable_brand_colours;
      const chartData = {
        ...chartConfig.template_json,
        chart_data: disableBrandColours
          ? chartConfig.template_json.chart_data
          : chartConfig.template_json.chart_type === 'time_chart' ||
            chartConfig.template_json.chart_type === 'waterfall_chart'
          ? {
              ...chartConfig.template_json.chart_data,
              series:
                chartConfig.template_json.chart_type === 'time_chart'
                  ? getColoredTimeSeries(
                      chartConfig.template_json.chart_data.series,
                      primaryColour,
                      secondaryColour,
                      targetsColour,
                    )
                  : getColoredWaterfallSeries(
                      chartConfig.template_json.chart_data.series,
                      primaryColour,
                      targetsColour,
                    ),
            }
          : chartConfig.template_json.chart_type === 'pie_chart'
          ? {
              ...chartConfig.template_json.chart_data,
              color: primaryColour,
            }
          : chartConfig.template_json.chart_data,
      };
      return {
        name: chartConfig?.name,
        chart_data: chartData,
        ...DEFAULT_CHART_COORDINATES,
        ...chart,
      };
    });

  const adjustedCharts = stackCharts(4, 2, chartsWithConfig as PreviewChartConfig[]);

  const saveDashboard = async () => {
    const newDashResponse = await createDashboard({
      name: dashboardName,
      pinned: isPinned,
      currency: null,
      cards: [],
      charts: [],
      id: -1,
    });

    if (!newDashResponse.success) {
      throw Error('Could not create a dashboard');
    }

    const newDashboardId = newDashResponse.dashboard.id;
    // Create the charts in parallel
    await Promise.all(
      adjustedCharts.map(async chart => {
        if (!chart.enabled) {
          return;
        }
        if (!chart.chart_data) {
          throw Error('Could not find chart data');
        }
        const chartResponse = await createChart(newDashboardId, {
          chart_type: chart.chart_data.chart_type,
          x: chart.x,
          y: chart.y,
          width: chart.w,
          height: chart.h,
        } as DashboardChartCard);

        // @ts-ignore
        if (!chartResponse.success) {
          throw Error('Could not create a chart');
        }

        // @ts-ignore
        const chartId = chartResponse.chart.id;
        const endpoint = `/api/v1/organisations/${organisationId}/charts/`;

        await fetcher(endpoint, {
          method: 'POST',
          body: JSON.stringify({ ...chart.chart_data, id: chartId }),
        });
      }),
    );
    dashboardVS.reload?.();
    Notification.success({ title: 'Dashboard saved' });
    history.push(`/dashboards/${newDashboardId}`);
  };

  const toggleEnabled = (index: number) => {
    const updatedCharts = [...charts];
    const chartIndex = updatedCharts.findIndex(chart => chart.id === index);
    updatedCharts[chartIndex].enabled = !updatedCharts[chartIndex].enabled;
    setCharts(updatedCharts);
  };

  return (
    <DashboardEditorContext.Provider
      value={{
        isPinned,
        dashboardName,
        charts: adjustedCharts,
        setIsPinned,
        setDashboardName,
        toggleEnabled,
        saveDashboard,
        primaryColour,
        setPrimaryColour,
        secondaryColour,
        setSecondaryColour,
        targetsColour,
        setTargetsColour,
      }}
    >
      {children}
    </DashboardEditorContext.Provider>
  );
};

export const useDashboardEditorContext = () => {
  const value = React.useContext(DashboardEditorContext);

  if (!value) {
    throw new Error('DashboardEditorProvider not found!');
  }

  return value;
};
