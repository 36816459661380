import { PropsWithChildren, useEffect, useMemo, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components';

import colors from '../../../colors';
import Checkbox from '../../../components/atoms/Checkbox';
import Icon from '../../../components/atoms/Icon';
import TextButton from '../../../components/atoms/TextButton';
import Typography from '../../../components/atoms/Typography';
import Row from '../../../components/layout/Row';
import { usePortalPositioning } from '../../../components/molecules/DropDown/usePortalPositioning';
import { useOutsideAlerter } from '../../../components/molecules/MultiSelect/hooks';
import SearchInput from '../../../components/molecules/SearchInput';
import { iconLookup } from '../../../components/organisms/OauthSidebar';
import { useNativeMetrics } from '../../../store/state/nativeMetrics/hooks';
import { theme } from '../../../theme';
import useCRMPipeLinesConnections from '../../connections/ConnectionsContext/useCRMPipeLinesConnections';
import useFinancialAccountsConnections from '../../connections/ConnectionsContext/useFinancialAccountsConnections';
import { filterByCategory, FormulaPickerOption } from './helpers';

export type SelectPlacement = 'bottom' | 'top';

interface Category {
  label: string;
  value: string;
  icon: React.ReactNode;
}

interface ReportEditorAddRowSelectProps {
  showDropDown: boolean;
  setShowDropDown: (showDropDown: boolean) => void;
  data: FormulaPickerOption[];
  handleAddRows: (values: string[]) => void;
  includeFormula?: boolean;
  includeFixedNumber?: boolean;
  includeRows?: boolean;
  includeBrackets?: boolean;
}

const StyledContainer = styled.div`
  width: 100%;
  position: relative;
  cursor: pointer;

  .expand-icon {
    position: absolute;
    top: ${({ theme }) => theme.spacing(1.25)};
    right: ${({ theme }) => theme.spacing(3.75)};
  }
`;

const StyledMenu = styled.div<{
  menuHeight: number;
  placement: SelectPlacement;
  container: DOMRect;
  dropDown: { width: number; height: number };
}>`
  position: absolute;
  width: ${({ container }) => `${container.width + 4}px`};
  max-height: ${({ menuHeight }) => `${menuHeight}px`};
  overflow-y: auto;
  background: ${({ theme }) => theme.palette.white};
  border: 1px solid ${({ theme }) => theme.palette.silver};
  box-shadow: 0px 1px 4px 2px rgba(0, 0, 0, 0.05);
  border-radius: ${({ theme }) => theme.spacing(1)};
  z-index: ${({ theme }) => theme.zIndex.high};

  ${({ placement, container: { top, left }, dropDown: { height: dropDownHeight } }) => {
    switch (placement) {
      case 'top':
        return `
        top: ${top - dropDownHeight - window.scrollY + 3}px;
        left: ${left + window.scrollX + 3}px;
      `;
      case 'bottom':
        return `
        top: ${top + window.scrollY - 3}px;
        left: ${left + window.scrollX - 3}px;
      `;
    }
  }};

  input {
    width: calc(100% - 2px);
    margin: ${({ theme }) => theme.spacing(0.5)};
  }
  .expand-icon {
    position: absolute;
    top: 5px;
    right: 5px;
  }
`;

const StyledSearchInputContainer = styled.div`
  position: sticky;
  top: 0;
  background-color: ${({ theme }) => theme.palette.white};
  z-index: 10;
`;

const StyledGroup = styled.div<{ selected?: boolean }>`
  height: ${({ theme }) => `${theme.spacing(8)}`};
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(2)};
  padding: ${({ theme }) => `${theme.spacing(2)} ${theme.spacing(4)}`};
  background-color: ${({ theme }) => theme.palette.backgroundGrey};
  color: ${({ theme, selected }) => (selected ? theme.palette.primary.main : theme.palette.midnight)};
  font-weight: ${({ theme, selected }) => (selected ? theme.font.weight.semibold : theme.font.weight.regular)};
  font-size: ${({ theme }) => theme.font.size.small};

  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &:empty {
    display: none;
  }
`;

const StyledMenuItem = styled.div<{ selected?: boolean; depth?: number }>`
  min-height: ${({ theme }) => `${theme.spacing(9)}`};
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(2)};
  padding: ${({ theme }) => `${theme.spacing(2)} ${theme.spacing(4)}`};
  padding-left: ${({ theme, depth = 4 }) => theme.spacing(depth)};
  background-color: ${({ theme, selected }) => (selected ? theme.palette.primary.offwhite : theme.palette.white)};
  color: ${({ theme, selected }) => (selected ? theme.palette.primary.main : theme.palette.midnight)};
  font-weight: ${({ theme, selected }) => (selected ? theme.font.weight.semibold : theme.font.weight.regular)};
  font-size: ${({ theme }) => theme.font.size.small};
  cursor: pointer;

  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .material-icons {
    width: 100%;
    display: ${({ selected }) => (selected ? 'block' : 'none')};
    color: ${({ theme }) => theme.palette.primary.main};
  }

  &:hover {
    background-color: ${({ theme, selected }) =>
      selected ? theme.palette.primary.offwhite : theme.palette.primary.offwhite};
  }
`;

const StyledDoneContainer = styled.div`
  position: sticky;
  bottom: 0;
  display: flex;
  justify-content: flex-end;
  padding: ${({ theme }) => `${theme.spacing(2)} ${theme.spacing(4)}`};
  background-color: ${({ theme }) => theme.palette.white};
  z-index: 10;
`;

const StyledCategoriesContainer = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing(6)};
  flex-wrap: wrap;
  padding: ${({ theme }) => theme.sizing(6)};
`;

const StyledCategoryTitleContainer = styled.div`
  position: sticky;
  top: 32px;
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.sizing(2)};
  padding: ${({ theme }) => theme.sizing(4)};
  background-color: ${({ theme }) => theme.palette.white};
  z-index: 10;
`;

const StyledIconsContainer = styled.div<{ iconsCount: number }>`
  width: ${({ iconsCount }) => `${iconsCount * 18 + 10}px`};
  display: flex;
`;

const StyledImage = styled.div<{ order: number; zIndex: number }>`
  position: relative;
  left: ${({ order }) => -((order + 1) * 6)}px;
  z-index: ${({ zIndex }) => zIndex};
`;

const StyledCategory = styled.div`
  height: 62px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${({ theme }) => theme.spacing(5)};
  padding: ${({ theme }) => `${theme.spacing(5)} ${theme.spacing(6)}`};
  border-radius: ${({ theme }) => theme.sizing(2.5)};
  background-color: ${({ theme }) => theme.palette.backgroundGrey};
  cursor: pointer;
`;

function ReportEditorAddRowSelect({
  data,
  handleAddRows,
  showDropDown,
  setShowDropDown,
  includeFormula = true,
  includeFixedNumber = true,
  includeRows = false,
  includeBrackets = true,
}: PropsWithChildren<ReportEditorAddRowSelectProps>) {
  const menuHeight = 300;

  const nativeMetricsVS = useNativeMetrics();

  // filtering custom metrics that are not used
  const pickerOptions = data.filter(option => {
    if (option.value.startsWith('nm:')) {
      const [, metricId] = option.value.split(':');

      const nativeMetricName = nativeMetricsVS.value?.[metricId]?.name;

      return !Boolean(metricId.startsWith('CUSTOM_') && !nativeMetricName);
    }

    return true;
  });

  const {
    containerCallbackRef,
    containerRef,
    containerRect,
    elementRef: dropDownRef,
    elementCallbackRef: dropDownCallbackRef,
    elementDimensions: dropDownDimensions,
  } = usePortalPositioning(showDropDown);

  useOutsideAlerter(dropDownRef, () => setShowDropDown(false));
  const financialConnections = [...new Set(useFinancialAccountsConnections())];
  const crmConnections = [...new Set(useCRMPipeLinesConnections())];
  let CATEGORIES: Category[] = [];

  // placement
  const distanceToBottom = window.innerHeight - (containerRef.current?.getBoundingClientRect().bottom || 0);
  const placement: SelectPlacement = distanceToBottom > menuHeight ? 'bottom' : 'top';

  const icons = (category: string) => {
    if (['financial', 'crm'].includes(category)) {
      const usedConnections = category === 'financial' ? financialConnections : crmConnections;
      return (
        <StyledIconsContainer iconsCount={usedConnections.length}>
          {usedConnections.map((connection, index) => {
            return (
              <StyledImage key={connection} order={index - 1} zIndex={usedConnections.length - index}>
                {iconLookup[connection]?.()}
              </StyledImage>
            );
          })}
        </StyledIconsContainer>
      );
    }

    return iconLookup[category]?.();
  };

  if (financialConnections.length) {
    CATEGORIES.push({
      label: 'Accounting',
      value: 'financial',
      icon: icons('financial'),
    });
  }
  if (crmConnections.length) {
    CATEGORIES.push({
      label: 'CRM',
      value: 'crm',
      icon: icons('crm'),
    });
  }

  CATEGORIES.push({
    label: 'ScaleXP',
    value: 'scalexp',
    icon: <img width={24} src={` /images/logos/scalexp-icon.svg`} alt="scalexp" />,
  });

  if (includeFormula) {
    CATEGORIES.push({
      label: 'Formula',
      value: 'custom',
      icon: <Icon size={6} name="calculate" color={theme.palette.granite} />,
    });
  }
  if (includeFixedNumber) {
    CATEGORIES.push({
      label: 'Fixed Number',
      value: 'constant',
      icon: <img width={24} src={` /images/logos/number.svg`} alt="number" />,
    });
  }
  if (includeRows) {
    CATEGORIES.push({
      label: 'Row',
      value: 'row',
      icon: <Icon size={6} name="toc" color={theme.palette.granite} />,
    });
  }
  if (includeBrackets) {
    CATEGORIES.push({
      label: 'Brackets',
      value: 'custom',
      icon: <Typography>()</Typography>,
    });
  }

  const menuSearchInputRef = useRef<HTMLInputElement>(null);

  const [values, setValues] = useState<string[]>([]);
  const [search, setSearch] = useState('');
  const [selectedCategory, setSelectedCategory] = useState<string | null>(null);

  useEffect(() => {
    if (selectedCategory) {
      window.scrollTo(0, document.body.scrollHeight);
    }
  }, [selectedCategory]);
  const handleCategoryClick = (category: string) => {
    if (category === 'constant' || category === 'custom') {
      setShowDropDown(false);
      handleAddRows([category]);
      return;
    }

    menuSearchInputRef.current?.focus();
    setSelectedCategory(category);
  };

  const handleItemClick = (itemValue: string) => {
    const newValues = [...values];
    const index = newValues.findIndex(value => value === itemValue);
    if (index === -1) {
      newValues.push(itemValue);
    } else {
      newValues.splice(index, 1);
    }
    setValues([...newValues]);
  };

  const handleSearchChange = (term: string) => {
    // clear category on search
    if (selectedCategory) {
      setSelectedCategory(null);
    }

    if (!term) {
      setValues([]);
    }
    setSearch(term);
  };

  // filter by category
  const dataFilteredByCategory = useMemo(() => filterByCategory(pickerOptions, selectedCategory), [
    pickerOptions,
    selectedCategory,
  ]);
  // search values
  const searchedValues = dataFilteredByCategory.filter(item => item.label.toLowerCase().includes(search.toLowerCase()));
  // group values
  const grouped = searchedValues.reduce(
    (group, item) => {
      const category = item.group;
      if (!category) {
        return group;
      }

      group[category] = group[category] ?? [];
      group[category].push(item);
      return group;
    },
    {} as {
      [group: string]: FormulaPickerOption[];
    },
  );

  useEffect(() => {
    if (!showDropDown) {
      setSelectedCategory(null);
      setSearch('');
      setValues([]);
    }
  }, [showDropDown]);

  const handleDone = () => {
    handleAddRows(values);
    setShowDropDown(false);
  };

  return (
    <StyledContainer ref={containerCallbackRef}>
      <Row width="100%">
        <SearchInput
          width="100%"
          value={search}
          hideIcon
          setValue={handleSearchChange}
          placeholder="Click to Start"
          onClick={() => setShowDropDown(true)}
          iconPosition="start"
        />

        <Icon name="expand_more" size={6} color={colors.blue[600]} className="expand-icon" />
      </Row>
      {showDropDown &&
        createPortal(
          <StyledMenu
            ref={dropDownCallbackRef}
            menuHeight={menuHeight}
            placement={placement}
            container={containerRect}
            dropDown={dropDownDimensions}
          >
            <StyledSearchInputContainer>
              <SearchInput
                width="100%"
                ref={menuSearchInputRef}
                value={search}
                setValue={handleSearchChange}
                placeholder="Type to search"
                onClick={() => setShowDropDown(true)}
                iconPosition="start"
              />
              <Icon name="expand_less" size={6} color={colors.blue[600]} className="expand-icon" />
            </StyledSearchInputContainer>
            {/* initial state no selected category and no search */}
            {!selectedCategory && !search && (
              <StyledCategoriesContainer>
                {CATEGORIES.map(category => (
                  <StyledCategory onClick={() => handleCategoryClick(category.value)}>
                    {category.icon}
                    <Typography color="secondary">{category.label}</Typography>
                  </StyledCategory>
                ))}
              </StyledCategoriesContainer>
            )}
            {/* selected category items */}
            {selectedCategory && (
              <StyledCategoryTitleContainer>
                {icons(selectedCategory)}
                <Typography>{CATEGORIES.find(category => category.value === selectedCategory)?.label}</Typography>
              </StyledCategoryTitleContainer>
            )}
            {searchedValues.length === 0 && <StyledMenuItem>No results found</StyledMenuItem>}
            {(search || selectedCategory) && (
              <>
                {Object.entries(grouped).map(([groupName, items]) => (
                  <>
                    {/* an exception for row category */}
                    {!(selectedCategory === 'row' && groupName === 'Row') && (
                      <StyledGroup key={groupName}>{groupName.toUpperCase()}</StyledGroup>
                    )}
                    {items.map(item => {
                      return (
                        <StyledMenuItem
                          key={`${item.label}${item.value}`}
                          onClick={() => handleItemClick(item.value)}
                          selected={values.includes(item.value)}
                        >
                          <Row vAlign="center">
                            <Checkbox checked={values.includes(item.value)} />
                            {item.source_id && icons(item.source_id)}
                            {item.label}
                          </Row>
                        </StyledMenuItem>
                      );
                    })}
                  </>
                ))}
                {searchedValues.length !== 0 && (
                  <StyledDoneContainer>
                    <TextButton width="120px" disabled={!values.length} onClick={handleDone} id="done-button">
                      Done
                    </TextButton>
                  </StyledDoneContainer>
                )}
              </>
            )}
          </StyledMenu>,
          document.body,
        )}
    </StyledContainer>
  );
}
export default ReportEditorAddRowSelect;
