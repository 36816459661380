import React from 'react';
import colors from 'scalexp/colors';
import Icon from 'scalexp/components/atoms/Icon';
import Typography from 'scalexp/components/atoms/Typography';
import Row from 'scalexp/components/layout/Row';
import { TimeChart, TimeSeries } from 'scalexp/features/chart/ChartCard/types';
import { MetricSchema2Complex } from 'scalexp/utils/metrics/metricSchema2';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';

import TextButton from '../../../components/atoms/TextButton';
import { useEditChartContext } from '../../../components/contexts/NewEditChartContext';
import Column from '../../../components/layout/Column';
import FormulaEditorModal from '../../../components/organisms/FormulaEditorModal';
import TimeChartSeries from '../TimeChartSeries';

const TimeSeriesWrapper = styled(Column)`
  min-height: unset;
  gap: 0;
  overflow: hidden;
  & div {
    min-height: unset;
  }
`;
const TimeSeriesContainer = styled(Column)`
  padding: 19px 16px;
  overflow: auto;
`;
const AddNewSeriesWrapper = styled(Column)`
  width: 100%;
  padding: 0 16px;
  height: 74px;
  flex: 0 0 auto;
  border-top: 1px solid #dfdfdf;
`;
const StyledNewSeriesButton = styled(TextButton)`
  border-radius: 12px;
  border-color: transparent;
  background-color: #f8f9fc;
`;

interface TimeChartOptionsProps {
  budgetOptions: ({ label: string; value: number } | { label: string; value: null })[];
  consolidatedBudgetOptions: ({ label: string; value: number } | { label: string; value: null })[];
  chart: TimeChart;
}

const NEW_SERIES_TEMPLATE: TimeSeries = {
  id: '',
  name: 'New Series',
  order: 100,
  color: '#D1DEFC',
  type: 'LineSeries',
  value_basis: 'ACTUAL',
  y_axis_side: 'LEFT',
  metric_schema: {
    schemaType: 'simple',
    decimals: 0,
    nodes: [
      {
        operator: 'add',
        data: {
          operator: 'native',
          metricId: 'REVENUE_TOTAL',
          isCashflow: false,
        },
      },
    ],
  },
  expand: false,
  stacked: false,
  invert_axis: false,
  periods: 12,
  offset: 0,
  budget: undefined,
  show_numbers: false,
};

const TimeChartOptions = ({ chart, budgetOptions, consolidatedBudgetOptions }: TimeChartOptionsProps) => {
  const { setConfig, setActiveSeries } = useEditChartContext();
  const [configureDetails, setConfigureDetails] = React.useState<null | {
    metricSchema: MetricSchema2Complex;
    updateMetricSchema: (updatedMetricSchema: MetricSchema2Complex) => void;
  }>(null);
  const [showModal, setShowModal] = React.useState(false);

  const handleAddSeries = () => {
    const newSeriesId = uuidv4();
    setConfig({
      ...chart,
      chart_data: {
        ...chart.chart_data,
        series: [...chart.chart_data.series, { ...NEW_SERIES_TEMPLATE, id: newSeriesId }],
      },
    });
    setActiveSeries(newSeriesId);
  };

  React.useEffect(() => {
    if (chart.chart_data.series.length > 2) {
      const addSeriesButton = document.querySelector('#add-series');
      addSeriesButton?.scrollIntoView({ block: 'end', behavior: 'smooth' });
    }
  }, [chart.chart_data.series.length]);
  React.useEffect(() => {
    if (chart.chart_data.series.length) {
      setActiveSeries(chart.chart_data.series[0].id);
    }
  }, []);

  const handleConfigure = (metricSchema: MetricSchema2Complex, index: number) => {
    setConfigureDetails({
      metricSchema,
      updateMetricSchema: (updatedMetricSchema: MetricSchema2Complex) => {
        setConfig({
          ...chart,
          chart_data: {
            ...chart.chart_data,
            series: chart.chart_data.series.map((series, seriesIndex) => {
              if (seriesIndex === index) {
                return {
                  ...series,
                  metric_schema: updatedMetricSchema,
                };
              }

              return series;
            }),
          },
        });
      },
    });
    setShowModal(true);
  };

  return (
    <TimeSeriesWrapper spacing="medium" width="100%">
      <TimeSeriesContainer width="100%" id="series-wrapper">
        {chart.chart_data.series.map((_, index) => {
          return (
            <TimeChartSeries
              key={uuidv4()}
              budgetOptions={budgetOptions}
              consolidatedBudgetOptions={consolidatedBudgetOptions}
              index={index}
              // @ts-ignore
              handleConfigure={handleConfigure}
            />
          );
        })}
      </TimeSeriesContainer>
      <AddNewSeriesWrapper width="100%" vAlign="center" hAlign="center">
        <StyledNewSeriesButton width="100%" size="xlarge" variant="secondary" onClick={handleAddSeries} id="add-series">
          <Row spacing="tiny" width="100%" height="100%" vAlign="center">
            <Icon size={6} name="add" color={colors.granite} />
            <Typography color="secondary">Add Series</Typography>
          </Row>
        </StyledNewSeriesButton>
      </AddNewSeriesWrapper>

      {showModal && configureDetails != null && (
        <FormulaEditorModal configureDetails={configureDetails} onHide={() => setShowModal(false)} />
      )}
    </TimeSeriesWrapper>
  );
};
export default TimeChartOptions;
