import * as am4charts from '@amcharts/amcharts4/charts';
import * as am4core from '@amcharts/amcharts4/core';
import { Dayjs } from 'dayjs';
import { DATE_FORMAT_DISPLAY } from 'scalexp/components/molecules/DateSelect/dateSupport';

import { getCurrencySymbol } from '../../../components/molecules/CurrencySelect/currencySupport';
import { theme } from '../../../theme';
import { getFiscalQuarter } from '../../../utils/dates';
import { getDateKey } from '../ChartCard/helpers';
import { AmChartConfig, Period, WaterfallChartData } from '../ChartCard/types';

const QUARTER_PERIODS = 3;
const YEAR_PERIODS = 12;

const X_AXIS_CATEGORY_TEMPLATE = {
  type: 'CategoryAxis',
  cursorTooltipEnabled: false,
  renderer: {
    labels: {
      location: 0.5,
    },
    minGridDistance: 50,
  },
  gridIntervals: [
    {
      timeUnit: 'month',
      count: 1,
    },
    {
      timeUnit: 'month',
      count: 2,
    },
  ],
  title: {
    text: '',
    fontSize: 10,
    fontWeight: 'LIGHTER',
  },
  dataFields: {
    category: 'category',
  },
};

const Y_AXIS_TEMPLATE = {
  type: 'ValueAxis',
  cursorTooltipEnabled: false,
  title: {
    text: '',
    fontSize: 12,
    fontWeight: 400,
  },
};

interface RechartsData {
  name: string;
  value: number;
  openValue: number;
  opacity: number;
  stepValue?: number;
  displayValue: number;
  color: string;
  show_numbers?: boolean;
}

export function getXAxis(data: WaterfallChartData, date: Dayjs, year: string, quarter: number, currency: string) {
  let template = X_AXIS_CATEGORY_TEMPLATE;
  const MAPPED_LABEL_OPTIONS = {
    // @ts-ignore
    CURRENCY: getCurrencySymbol[currency],
    YEAR_TO_DATE: `${year} YTD`,
    YEAR: year,
    QUARTER: `Q${quarter} ${year}`,
    MONTH: date.format(DATE_FORMAT_DISPLAY),
  };
  const prepend = data.x_axis_label_prepend ? MAPPED_LABEL_OPTIONS[data.x_axis_label_prepend] : '';
  let text = data.x_axis_label || '';
  if (data.period !== 'YEAR_TO_DATE' && data.x_axis_label.includes('YTD')) {
    text = '';
  }
  const append = data.x_axis_label_append ? MAPPED_LABEL_OPTIONS[data.x_axis_label_append] : '';
  return {
    ...template,
    title: {
      ...template.title,
      text: `${prepend} ${text} ${append}`,
    },
  };
}

export function getYAxis(
  data: WaterfallChartData,
  date: Dayjs,
  year: string,
  quarter: number,
  currency: string,
  chartDataType: string,
) {
  let text = data.y_axis_label?.replaceAll(/#|%/g, '') ?? '';
  return {
    ...Y_AXIS_TEMPLATE,
    title: {
      ...Y_AXIS_TEMPLATE.title,
      text: text,
    },
  };
}

export function getWaterfallChartDateBounds(
  numberOfPeriods: number,
  maxOffset: number,
  period: Period,
  focalDate: Dayjs,
  financialYearStart?: number,
) {
  // generate the date keys for the accountSummary
  const multiplier = period === 'MONTH' ? 1 : period === 'QUARTER' ? QUARTER_PERIODS : YEAR_PERIODS;
  let dateKeys = Array.from({ length: numberOfPeriods }, (_, i) => {
    const offset = (i - maxOffset) * multiplier;
    const { fiscalYear, fiscalQuarter } = getFiscalQuarter(focalDate, -offset, financialYearStart);
    return getDateKey(period, fiscalYear, fiscalQuarter, focalDate.subtract(offset, 'month'));
  }).reverse();

  // remove duplicates
  return Array.from(new Set(dateKeys));
}

export const getamWaterfallChartInstance = (
  config: AmChartConfig,
  element: HTMLElement,
  chartDataType: string,
  currency: string,
) => {
  // NOTE: This is a little messy, but I've essentially just copied the
  // code from https://www.amcharts.com/demos/waterfall-chart/
  // We could use Recharts.js but I've decided that it's probably best
  // for now just to do this so we don't need to reimplement export
  // functionality etc.

  // The general gist is that we:
  //
  //  1. take all the accountSummary specified for the chart
  //  2. sum each accountSummary into a single value
  //  3. display each of these are columns, ordered by zIndex of the accountSummary
  //  4. add in a "fudge factor" column called "All other" to make up for any
  //     discrepancies.
  //
  const dataWithoutOther = [...config.series]
    .sort((a, b) => a.zIndex - b.zIndex)
    .map((series, index) =>
      config.data.reduce<RechartsData>(
        (accum, entry) => {
          const seriesItem = (series as unknown) as am4charts.Series & { show_numbers: boolean; opacity: number };
          const value = accum.displayValue + 1 * (entry[series.dataFields.valueY] || 0);
          const result = {
            ...accum,
            displayValue: value,
            opacity: seriesItem.opacity,
            show_numbers: seriesItem.show_numbers,
            // If we have a fill for negative, and value is negative, use it.
            color: value <= 0 && series.fill_to_use_when_negative ? series.fill_to_use_when_negative : series.fill,
          };
          return result;
        },
        {
          // NOTE: we have a little hack here taken from
          // https://www.amcharts.com/docs/v4/tutorials/handling-repeating-categories-on-category-axis/
          // to handle when we have two categories with the same name
          name: `${series.name} (${index})`,
          value: 0,
          openValue: 0,
          opacity: 1,
          stepValue: 0,
          displayValue: 0,
          color: series.fill,
        },
      ),
    )
    .reduce<Array<RechartsData>>(
      (accum, entry) =>
        [...accum].concat([
          {
            ...entry,
            openValue: accum[accum.length - 1]?.value || 0,
            value: (accum[accum.length - 1]?.value || 0) + entry.displayValue,
            stepValue: (accum[accum.length - 1]?.value || 0) + entry.displayValue,
            displayValue: entry.displayValue,
            // show_numbers: entry.show_numbers,
          },
        ]),
      [],
    );

  // Add in a "fudge factor" to make sure the last bar has openValue 0.
  // Theorietically you should be able to achieve this if all your accountSummary
  // line up exactly.
  const last = dataWithoutOther[dataWithoutOther.length - 1];
  const oneBeforeLast = dataWithoutOther[dataWithoutOther.length - 2];

  last.value = last.displayValue;
  last.openValue = 0;
  last.stepValue = undefined;

  const data = [
    ...dataWithoutOther.slice(0, dataWithoutOther.length - 1),
    {
      name: 'All other',
      value: last.value,
      openValue: oneBeforeLast.value,
      stepValue: last.value,
      displayValue: last.value - oneBeforeLast.value,
      color: '#afcae7',
      color_to_use_when_negative: '#afcae7',
      show_numbers: config.series.some(
        series => ((series as unknown) as am4charts.Series & { show_numbers: boolean }).show_numbers,
      ),
    },
    last,
  ];

  // Create the actual chart
  const chart = am4core.create(element, am4charts.XYChart);
  chart.fontSize = 12;

  chart.data = data;

  var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());

  // Make sure the labels don't run into each other. This is take from
  // https://www.amcharts.com/docs/v4/tutorials/wrapping-and-truncating-axis-labels/#Auto_fitting_labels
  let label = categoryAxis.renderer.labels.template;
  label.wrap = true;

  // NOTE: we have a little hack here taken from
  // https://www.amcharts.com/docs/v4/tutorials/handling-repeating-categories-on-category-axis/
  // to handle when we have two categories with the same name
  categoryAxis.events.on('sizechanged', function (ev) {
    let axis = ev.target;
    axis.renderer.labels.template.maxWidth = 120;
    axis.renderer.labels.template.rotation = -45;
    axis.renderer.labels.template.textAlign = 'end';
    axis.renderer.labels.template.horizontalCenter = 'right';
  });

  categoryAxis.dataFields.category = 'name';
  // Make sure we pick up fontSize from django admin
  categoryAxis.renderer.minGridDistance = 40;
  categoryAxis.renderer.labels.template.adapter.add('textOutput', function (text) {
    return text?.replace(/ \(.*/, '');
  });

  const xAxis = config.xAxes[0];
  categoryAxis.renderer.minGridDistance = 40;
  categoryAxis.renderer.labels.template.adapter.add('textOutput', function (text) {
    return text?.replace(/ \(.*/, '');
  });

  // Mix in any title we get from the api
  if (xAxis) {
    categoryAxis.fontSize = xAxis?.title?.fontSize;
    Object.assign(categoryAxis.title, xAxis.title);
  }

  config.yAxes.forEach(axis => {
    const amAxis = chart.yAxes.push(new am4charts.ValueAxis());
    Object.assign(amAxis.title, axis.title);
  });

  chart.yAxes.push(new am4charts.ValueAxis());
  chart.maskBullets = false;

  var columnSeries = chart.series.push(new am4charts.ColumnSeries());
  columnSeries.dataFields.categoryX = 'name';
  columnSeries.columns.template.propertyFields.opacity = 'opacity';

  columnSeries.dataFields.valueY = 'value';
  columnSeries.dataFields.openValueY = 'openValue';
  columnSeries.sequencedInterpolation = true;
  columnSeries.interpolationDuration = 1500;
  columnSeries.tooltipText = '{displayValue}';

  let labelBullet = columnSeries.bullets.push(new am4charts.LabelBullet());
  configureBulletLabel(labelBullet, '{value}', am4core.color('#ffffff'), am4core.color('#ffffff'));

  // (only for waterfall) Add adapter to hide the label if the previous value is the same
  labelBullet.label.adapter.add('visible', function (visible, target) {
    const dataItem = target.dataItem;
    if ((dataItem && dataItem.values.valueY.value === 0) || !data[target.dataItem!.index]?.show_numbers) {
      return false;
    }
    return true;
  });
  // Add adapter for the first label

  var columnTemplate = columnSeries.columns.template;
  columnTemplate.strokeOpacity = 0;
  columnTemplate.propertyFields.fill = 'color';

  var stepSeries = chart.series.push(new am4charts.StepLineSeries());

  stepSeries.dataFields.categoryX = 'name';
  stepSeries.dataFields.valueY = 'stepValue';
  stepSeries.noRisers = true;
  stepSeries.stroke = new am4core.InterfaceColorSet().getFor('alternativeBackground');
  stepSeries.strokeDasharray = '3,3';
  stepSeries.interpolationDuration = 2000;
  stepSeries.sequencedInterpolation = true;

  // because column width is 80%, we modify start/end locations so that step would start with column and end with next column
  stepSeries.startLocation = 0.1;
  stepSeries.endLocation = 1.1;

  chart.cursor = new am4charts.XYCursor();
  chart.cursor.behavior = 'none';

  // Set options for export. This will exclude all other fields aside from
  // name and displayValue. We may want to include more fields in the
  // future but I'll start with these two for waterfall charts
  chart.exporting.dataFields = {
    name: 'Name',
    displayValue: 'Value',
  };

  if (chart) {
    chart.paddingRight = 20;
    chart.paddingBottom = 10;
    chart.paddingTop = 20;
  }
  if (chart instanceof am4charts.XYChart) {
    let [xAxis = null] = chart.xAxes;
    let [yAxis = null] = chart.yAxes;
    if (xAxis) {
      xAxis.renderer.grid.template.disabled = true;
      let startLine = xAxis.axisRanges.create();
      startLine.grid.stroke = am4core.color(theme.palette.primary.offwhite);
      startLine.grid.strokeWidth = 1;
      startLine.grid.strokeOpacity = 1;
      let endLine = xAxis.axisRanges.create();
      endLine.grid.stroke = am4core.color(theme.palette.primary.offwhite);
      endLine.grid.strokeWidth = 1;
      endLine.grid.strokeOpacity = 1;
      let lastItem = chart.data[chart.data.length - 1];
      // @ts-ignore - Typing is wrong
      endLine.category = lastItem.name;
      endLine.grid.location = 1;
    }
    if (yAxis) {
      yAxis.renderer.grid.template.stroke = am4core.color(theme.palette.primary.offwhite);
      yAxis.renderer.grid.template.strokeOpacity = 1;
    }
  }
  switch (chartDataType) {
    case 'numerical':
      chart.numberFormatter.numberFormat = '#';
      break;
    case 'percentage':
      chart.numberFormatter.numberFormat = "#.## '%'";
      break;
    case 'monetary':
      chart.numberFormatter.numberFormat = `'${getCurrencySymbol(currency)}'#,###`;
      break;
    default:
      break;
  }
  return chart;
};

export const configureBulletLabel = (
  labelBullet: am4charts.LabelBullet,
  valueKey: string,
  fill: am4core.Optional<am4core.Color | am4core.Pattern | am4core.LinearGradient | am4core.RadialGradient>,
  stroke: am4core.Color | am4core.Pattern | am4core.LinearGradient | am4core.RadialGradient,
  isPie: boolean = false,
) => {
  labelBullet.label.fill = fill; // Add a contrasting stroke
  labelBullet.label.background.stroke = stroke; // Match stroke color with series color
  labelBullet.label.text = valueKey;

  // Configure the label bullet
  labelBullet.label.truncate = false;
  labelBullet.label.horizontalCenter = 'middle';
  labelBullet.label.verticalCenter = 'middle';
  // labelBullet.label.rotation = -45;
  // labelBullet.label.horizontalCenter = 'middle';
  labelBullet.label.fontWeight = 'bold';
  labelBullet.label.fontSize = 10;

  // Set the label color to match the series color
  labelBullet.label.strokeWidth = 2; // Set stroke width
  labelBullet.label.dy = 5;

  labelBullet.label.stroke = am4core.color('#ffffff'); // Add a contrasting stroke
  labelBullet.label.strokeWidth = 2; // Set stroke width

  labelBullet.label.background.fillOpacity = 1; // Set background opacity
  labelBullet.label.background.strokeWidth = 1; // Set stroke width
  labelBullet.label.padding(3, 3, 3, 3); // Add padding to the label

  // Set the label color to match the series color
  labelBullet.label.adapter.add('fill', (fill, target) => {
    return ((target.dataItem?.dataContext as RechartsData).color as unknown) as am4core.Color;
  });
  // Dynamically adjust dy based on label size and position

  if (isPie) {
    // Add adapter to format the label text to show minimal values
    labelBullet.label.adapter.add('text', function (text, target) {
      const value = target.dataItem!.values.value.value;
      const absoluteValue = Math.abs(value);
      if (absoluteValue >= 1000000) {
        return (value / 1000000).toFixed(1) + 'M';
      } else if (absoluteValue >= 1000) {
        return (value / 1000).toFixed(1) + 'K';
      }
      return text;
    });
    labelBullet.label.adapter.add('fill', function (fill, target) {
      const dataItem = target.dataItem;
      if (dataItem) {
        return (dataItem.dataContext as { color: am4core.Color }).color;
      }
      return fill;
    });
    labelBullet.label.adapter.add('visible', function (visible, target) {
      const dataItem = target.dataItem;
      if (dataItem && dataItem.values.value.value === 0) {
        return false;
      }

      return true;
    });
  } else {
    labelBullet.label.adapter.add('dy', function (dy, target) {
      const dataItem = target.dataItem;
      if (dataItem) {
        const value = dataItem.values.valueY.value;
        if (value < 0) {
          return 10; // Move label down for negative values
        }
      }
      return dy;
    });
    labelBullet.label.adapter.add('text', function (text, target) {
      const value = target.dataItem!.values.valueY.value;
      const absoluteValue = Math.abs(value);
      if (absoluteValue >= 1000000) {
        return (value / 1000000).toFixed(1) + 'M';
      } else if (absoluteValue >= 1000) {
        return (value / 1000).toFixed(1) + 'K';
      }
      return text;
    });
    // Add adapter to hide the label if the previous value is the same
    labelBullet.label.adapter.add('visible', function (visible, target) {
      const dataItem = target.dataItem;
      if (dataItem && dataItem.values.valueY.value === 0) {
        return false;
      }

      return true;
    });
  }
  // Add adapter to format the label text to show minimal values
};
