import React, { useState, useEffect, useRef, useMemo, PropsWithChildren } from 'react';
import { createPortal } from 'react-dom';
import styled, { css } from 'styled-components';

import colors from '../../../colors';
import Divider from '../../atoms/Divider';
import Icon from '../../atoms/Icon';
import Loader from '../../atoms/Loader';
import Typography from '../../atoms/Typography';
import Row from '../../layout/Row';
import { usePortalPositioning } from '../DropDown/usePortalPositioning';
import { useFirstRender, useOutsideAlerter } from '../MultiSelect/hooks';
import SearchInput from '../SearchInput';

type SelectSize = 'tiny' | 'small' | 'medium' | 'large' | 'xlarge';
type SelectPlacement = 'topStart' | 'topEnd' | 'bottomStart' | 'bottomEnd';

export type Groups<Value> = {
  [group: string]: { items: DataItem<Value>[]; isExpanded: boolean; order: number };
};

interface DataItem<Value = string> {
  label: string;
  value: Value;
  [key: string]: any;
}

type SelectData<Value> = (DataItem<Value> | 'Divider')[];

interface SelectProps<Value> {
  data: SelectData<Value>;
  size?: SelectSize;
  width?: string;
  value?: Value;
  searchable?: boolean;
  selectWidth?: number | 'maxOptionWidth';
  selectHeight?: number;
  selectPlaceholder?: string;
  placement?: SelectPlacement;
  alignment?: 'Start' | 'End';
  isLoadingOptions?: boolean;
  expandableGroups?: boolean;
  showCheckMark?: boolean;
  label?: string;
  disabled?: boolean;
  groupBy?: string;
  orderGroups?: 'ASCENDING' | 'DESCENDING';
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  onChange?: (value: Value) => void;
  onClose?: (value: Value | null) => void;
  onSearchChange?: (value: string) => void;
  renderValue?: (value: Value, item: DataItem<Value>) => React.ReactNode;
  actionComponent: React.ReactNode;
  renderSelectItem?: (value: Value, item: DataItem<Value>) => React.ReactNode;
  renderSelectGroup?: (groupName: string) => React.ReactNode;
  zIndex?: 'high' | 'highest';
}

const StyledContainer = styled.div<{ width: string }>`
  width: ${({ width }) => width};
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(2.5)};
`;

const StyledValue = styled.div`
  cursor: pointer;
`;

const StyledSelect = styled.div<{
  size: SelectSize;
  customWidth?: number | 'maxOptionWidth';
  height: number;
  usedPlacement: SelectPlacement;
  container: DOMRect;
  select: { width: number; height: number };
  zIndex: 'high' | 'highest';
  containerWidth: string;
}>`
  position: absolute;
  width: ${({ customWidth, container }) => {
    if (customWidth === 'maxOptionWidth') {
      return `unset`;
    }
    return `${customWidth ?? container.width}px`;
  }};
  min-width: ${({ customWidth, containerWidth }) => {
    if (customWidth === 'maxOptionWidth' && containerWidth.includes('px')) {
      return containerWidth;
    }
  }};
  max-height: ${({ height }) => `${height}px`};
  overflow-y: auto;
  z-index: ${({ theme, zIndex }) => theme.zIndex[zIndex]};
  border: 1px solid ${({ theme }) => theme.palette.fieldGrey};
  border-top: unset;
  background-color: ${({ theme }) => theme.palette.white};

  ${({
    usedPlacement,
    container: { top, left, width, height },
    select: { height: selectHeight, width: selectWidth },
    customWidth,
  }) => {
    const usedWidth = customWidth === 'maxOptionWidth' ? selectWidth : customWidth ?? selectWidth;
    switch (usedPlacement) {
      case 'topStart':
        return css`
          top: calc(${top}px - ${selectHeight}px + ${window.scrollY}px);
          left: calc(${left}px + ${window.scrollX}px);
        `;
      case 'topEnd':
        return css`
          top: calc(${top}px - ${selectHeight}px + ${window.scrollY}px);
          left: calc(${left}px + ${window.scrollX}px - ${usedWidth}px + ${width}px);
        `;
      case 'bottomStart':
        return css`
          top: calc(${top}px + ${height}px + ${window.scrollY}px);
          left: calc(${left}px + ${window.scrollX}px);
        `;
      case 'bottomEnd':
        return css`
          top: ${top + height + window.scrollY}px;
          left: ${left - usedWidth + width + window.scrollX}px;
        `;
    }
  }};

  ${({ size, theme }) => {
    switch (size) {
      case 'tiny':
      case 'small': {
        return css`
          border-bottom-left-radius: ${theme.spacing(1)};
          border-bottom-right-radius: ${theme.spacing(1)};
        `;
      }
      case 'medium':
      case 'large':
      case 'xlarge': {
        return css`
          border-bottom-left-radius: ${theme.spacing(2)};
          border-bottom-right-radius: ${theme.spacing(2)};
        `;
      }
      default:
        return '';
    }
  }}
`;

const StyledGroup = styled.div<{ selected?: boolean }>`
  height: ${({ theme }) => `${theme.spacing(9.5)}`};
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(2)};
  padding: ${({ theme }) => `${theme.spacing(2)} ${theme.spacing(4)}`};
  background-color: ${({ theme }) => theme.palette.backgroundGrey};
  color: ${({ theme, selected }) => (selected ? theme.palette.primary.main : theme.palette.midnight)};
  font-weight: ${({ theme, selected }) => (selected ? theme.font.weight.semibold : theme.font.weight.regular)};
  font-size: ${({ theme }) => theme.font.size.small};
  cursor: pointer;

  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const StyledSelectPlaceholder = styled.div`
  height: ${({ theme }) => `${theme.spacing(7)}`};
  padding: ${({ theme }) => `${theme.spacing(2)} ${theme.spacing(4)}`};
  color: ${({ theme }) => theme.palette.granite};
  font-size: ${({ theme }) => theme.font.size.tiny};
  line-height: ${({ theme }) => theme.font.lineheight.tiny};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const LoadingOptions = styled(Row)`
  padding: 10px 20px;
`;

const StyledSearchContainer = styled.div<{
  zIndex: 'high' | 'highest';
}>`
  padding: ${({ theme }) => `${theme.spacing(3)} ${theme.spacing(4)}`};
  position: sticky;
  top: 0;
  background-color: white;
  z-index: ${({ theme, zIndex }) => theme.zIndex[zIndex]};
`;

const StyledSelectItem = styled.div<{ selected?: boolean }>`
  min-height: ${({ theme }) => `${theme.spacing(9)}`};
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(2)};
  padding: ${({ theme }) => `0 ${theme.spacing(3)} 0 ${theme.spacing(4)}`};
  background-color: ${({ theme, selected }) => (selected ? theme.palette.silver : theme.palette.white)};
  color: ${({ theme, selected }) => (selected ? theme.palette.primary.main : theme.palette.midnight)};
  cursor: pointer;

  span:not(.material-icons-round) {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &:hover {
    background-color: ${({ theme }) => theme.palette.primary.offwhite};
  }
`;

function ActionSelect<Value = string>({
  data,
  actionComponent,
  size = 'small',
  width = '100%',
  value,
  searchable = false,
  isLoadingOptions = false,
  alignment = 'Start',
  expandableGroups = false,
  showCheckMark = true,
  selectHeight = 386,
  selectWidth,
  orderGroups = 'DESCENDING',
  selectPlaceholder,
  label,
  disabled,
  groupBy,
  renderSelectItem,
  renderSelectGroup,
  onChange,
  onClose,
  onClick,
  onSearchChange,
  zIndex = 'high',
  placement,
}: PropsWithChildren<SelectProps<Value>>) {
  const [showSelect, setShowSelect] = useState(false);
  const {
    containerCallbackRef,
    containerRef,
    containerRect,
    elementRef: selectRef,
    elementCallbackRef: selectCallbackRef,
    elementDimensions: selectDimensions,
  } = usePortalPositioning(showSelect, setShowSelect);

  const searchInputRef = useRef<HTMLInputElement>(null);
  const selectedItemRef = useRef<HTMLDivElement>(null);

  const [innerValue, setInnerValue] = useState<Value | null>(null);
  const [search, setSearch] = useState('');
  const [groups, setGroups] = useState<Groups<Value>>({});

  const renderGroupedOption = useMemo(
    () =>
      Object.entries(groups)
        .sort(([a], [b]) => (orderGroups === 'ASCENDING' ? a.localeCompare(b) : b.localeCompare(a)))
        .map(([groupName, group]) => (
          <>
            {!!groupName && (
              <StyledGroup key={groupName} onClick={() => expandableGroups && handleGroupExpand(groupName)}>
                {renderSelectGroup ? renderSelectGroup(groupName) : <>{groupName.toUpperCase()}</>}
                {expandableGroups && (
                  <Icon
                    name={group.isExpanded ? 'expand_less' : 'expand_more'}
                    size={6}
                    color={colors.blue[600]}
                    marginRight={0}
                  />
                )}
              </StyledGroup>
            )}
            {group.isExpanded &&
              group.items.map(item => {
                const isSelected = value !== undefined ? value === item.value : innerValue === item.value;
                return (
                  <StyledSelectItem
                    key={`${item.label}${item.value}`}
                    onClick={() => handleItemClick(item.value)}
                    selected={isSelected}
                    ref={isSelected ? selectedItemRef : undefined}
                  >
                    {renderSelectItem ? (
                      renderSelectItem(item.value, item)
                    ) : (
                      <Typography weight="regular">{item.label}</Typography>
                    )}
                    {showCheckMark && isSelected && <Icon size={5} name="done" color={colors.blue[600]} rounded />}
                  </StyledSelectItem>
                );
              })}
          </>
        )),
    [groups, orderGroups],
  );

  // placement
  const distanceToBottom = window.innerHeight - (containerRef.current?.getBoundingClientRect().bottom || 0);
  const usedPlacement: SelectPlacement = placement
    ? placement
    : distanceToBottom > selectHeight
    ? `bottom${alignment}`
    : `top${alignment}`;

  const handleItemClick = (itemValue: Value) => {
    setInnerValue(itemValue);
    onChange?.(itemValue);
    setShowSelect(false);
  };

  const focusInput = () => {
    searchInputRef.current?.focus();
  };

  const isFirstRender = useFirstRender();
  useEffect(() => {
    // on close
    if (!showSelect) {
      // clear search on select close
      setSearch('');
      onSearchChange?.('');

      // if it is not the first render we invoke onClose
      if (!isFirstRender) {
        onClose?.(value ?? innerValue);
      }
    }

    if (showSelect) {
      // focus on show
      focusInput();
      // scroll to selected item
      selectedItemRef.current?.scrollIntoView?.({ block: 'center', inline: 'center' });
      // focus input on key down
      document.addEventListener('keydown', focusInput);
    }
    return () => document.removeEventListener('keydown', focusInput);
  }, [showSelect]);
  useOutsideAlerter(selectRef, () => setShowSelect(false));

  const searchedValuesData = useMemo(
    () => data.filter(item => item === 'Divider' || item.label.toLowerCase().includes(search.toLowerCase())),
    [data, search],
  );
  useEffect(() => {
    let order = 0;
    const grouped = searchedValuesData.reduce((groups, item) => {
      // ignoring dividers on grouping for
      if (item === 'Divider') {
        return groups;
      }
      const groupName = groupBy ? item[groupBy] : undefined;
      if (groupName) {
        groups[groupName] = groups[groupName] ?? { items: [], isExpanded: true, order: order++ };
        groups[groupName].items.push(item);

        return groups;
      }

      groups[''] = groups[''] ?? { items: [], isExpanded: true };
      groups[''].items.push(item);
      return groups;
    }, {} as Groups<Value>);
    setGroups(grouped);
  }, [searchedValuesData]);

  const handleGroupExpand = (groupName: string) => {
    setGroups({ ...groups, [groupName]: { ...groups[groupName], isExpanded: !groups[groupName].isExpanded } });
  };

  return (
    <StyledContainer ref={containerCallbackRef} onKeyDown={focusInput} width={width} onClick={onClick}>
      {label && <Typography weight="regular">{label}</Typography>}
      <StyledValue onClick={() => !disabled && setShowSelect(!showSelect)}>{actionComponent}</StyledValue>
      {showSelect &&
        createPortal(
          <StyledSelect
            ref={selectCallbackRef}
            size={size}
            customWidth={selectWidth}
            height={selectHeight}
            usedPlacement={usedPlacement}
            container={containerRect}
            select={selectDimensions}
            containerWidth={width}
            zIndex={zIndex}
          >
            {selectPlaceholder && <StyledSelectPlaceholder>{selectPlaceholder}</StyledSelectPlaceholder>}
            {searchable && (
              <StyledSearchContainer zIndex={zIndex}>
                <SearchInput
                  ref={searchInputRef}
                  value={search}
                  setValue={value => {
                    setSearch(value);
                    onSearchChange?.(value);
                  }}
                  placeholder="search"
                  width="100%"
                />
              </StyledSearchContainer>
            )}
            {(selectPlaceholder || searchable) && <Divider noMargin />}
            {searchable && isLoadingOptions ? (
              <LoadingOptions>
                <Loader />
                <Typography weight="regular">Loading options ...</Typography>
              </LoadingOptions>
            ) : searchable && searchedValuesData.length === 0 ? (
              <StyledSelectItem>
                <Row vAlign="center" spacing="small">
                  <Icon size={5} name="dangerous" color={colors.granite} marginRight={0} rounded />
                  <Typography weight="regular">No results found</Typography>
                </Row>
              </StyledSelectItem>
            ) : (
              renderGroupedOption
            )}
          </StyledSelect>,
          document.body,
        )}
    </StyledContainer>
  );
}
export default ActionSelect;
