export const getCurrencySymbol = (currencyCode: CurrencyCode): string => {
  let currencySymbol = new Intl.NumberFormat('en-GB', {
    style: 'currency',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
    currencyDisplay: currencyCode === 'USD' ? 'narrowSymbol' : 'symbol',
    currency: currencyCode,
  })
    .format(0)
    .slice(0, -1);

  if (currencyCode === 'SGD') {
    currencySymbol = currencySymbol.replace('SGD', 'SG$');
  }
  if (currencyCode === 'ZAR') {
    currencySymbol = currencySymbol.replace('ZAR', 'R');
  }
  if (currencyCode === 'MYR') {
    currencySymbol = currencySymbol.replace('MYR', 'RM');
  }

  return currencySymbol.trim();
};

export const SUPPORTED_CURRENCIES: { [code: string]: string } = {
  GBP: getCurrencySymbol('GBP'),
  USD: getCurrencySymbol('USD'),
  EUR: getCurrencySymbol('EUR'),
  CAD: getCurrencySymbol('CAD'),
  AUD: getCurrencySymbol('AUD'),
  NZD: getCurrencySymbol('NZD'),
  SGD: getCurrencySymbol('SGD'),
  ZAR: getCurrencySymbol('ZAR'),
  JPY: getCurrencySymbol('JPY'),
  INR: getCurrencySymbol('INR'),
  HKD: getCurrencySymbol('HKD'),
  PHP: getCurrencySymbol('PHP'),
  CHF: getCurrencySymbol('CHF'),
  NGN: getCurrencySymbol('NGN'),
  BRL: getCurrencySymbol('BRL'),
  KES: getCurrencySymbol('KES'),
  AED: getCurrencySymbol('AED'),
  BHD: getCurrencySymbol('BHD'),
  CNY: getCurrencySymbol('CNY'),
  PLN: getCurrencySymbol('PLN'),
  MDL: getCurrencySymbol('MDL'),
  THB: getCurrencySymbol('THB'),
  MXN: getCurrencySymbol('MXN'),
  IDR: getCurrencySymbol('IDR'),
  HUF: getCurrencySymbol('HUF'),
  MYR: getCurrencySymbol('MYR'),
  MAD: getCurrencySymbol('MAD'),
} as const;

export type CurrencyCode = typeof SUPPORTED_CURRENCIES[number];
export const getCurrencyName = (code: string) => {
  const currencies = {
    GBP: 'British pound sterling (GBP) £',
    USD: 'US dollar (USD) $',
    EUR: 'Euro (EUR) €',
    CAD: 'Canadian Dollar (CAD) $',
    AUD: 'Australian Dollar (AUD) $',
    NZD: 'New Zealand Dollar (NZD) $',
    SGD: 'Singapore Dollar (SGD) $',
    ZAR: 'South Africa Rand (ZAR) R',
    JPY: 'Japan Yen (JPY) ¥',
    INR: 'Indian Rupee (INR) ₹',
    HKD: 'Hong Kong Dollar (HKD) $',
    PHP: 'Philippine Peso (PHP) ₱',
    CHF: 'Swiss Franc (CHF) CHF',
    NGN: 'Nigerian Naira (NGN) ₦',
    BRL: 'Brazilian Real (BRL) R$',
    KES: 'Kenyan Shilling (KES) KSh',
    AED: 'United Arab Emirates Dirham (AED) د.إ',
    BHD: 'Bahraini Dinar (BHD) BD',
    CNY: 'Chinese Yuan (CNY) ¥',
    PLN: 'Polish Zloty (PLN) zł',
    MDL: 'Moldovan Leu (MDL) L',
    THB: 'Thai Baht (THB) ฿',
    MXN: 'Mexican Peso (MXN) $',
    IDR: 'Indonesian Rupiah (IDR) Rp',
    HUF: 'Hungarian Forint (HUF) Ft',
    MYR: 'Malaysian Ringgit (MYR) RM',
    MAD: 'Moroccan Dirham (MAD) د.م.',
  } as const;
  return currencies[code as keyof typeof currencies];
};
export const isSupportedCurrency = (currencyCode: string | null | undefined) => {
  return currencyCode && Object.keys(SUPPORTED_CURRENCIES).includes(currencyCode as CurrencyCode);
};
