import { PropsWithChildren, useEffect, useMemo, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import Tooltip from 'scalexp/components/molecules/Tooltip';
import { useChartMetricsOptions } from 'scalexp/features/chart/ChartCard/hooks';
import { InvoicedRevenueMetricId, SalesMetricIds } from 'scalexp/service/types/metricSchema';
import { MetricSchema2DataSource, MetricSchema2Simple } from 'scalexp/utils/metrics/metricSchema2';

import colors from '../../../colors';
import Icon from '../../../components/atoms/Icon';
import Typography from '../../../components/atoms/Typography';
import Row from '../../../components/layout/Row';
import { usePortalPositioning } from '../../../components/molecules/DropDown/usePortalPositioning';
import { useOutsideAlerter } from '../../../components/molecules/MultiSelect/hooks';
import SearchInput from '../../../components/molecules/SearchInput';
import { iconLookup } from '../../../components/organisms/OauthSidebar';
import { theme } from '../../../theme';
import useCRMPipeLinesConnections from '../../connections/ConnectionsContext/useCRMPipeLinesConnections';
import useFinancialAccountsConnections from '../../connections/ConnectionsContext/useFinancialAccountsConnections';
import { filterByCategory, FormulaPickerOption } from './helpers';
import {
  SelectPlacement,
  SelectSize,
  StyledCategoriesContainer,
  StyledCategory,
  StyledCategoryTitleContainer,
  StyledContainer,
  StyledGroup,
  StyledIconsContainer,
  StyledImage,
  StyledMenuItem,
  StyledSearchInputContainer,
  StyledSelect,
  StyledValue,
} from './styles';

interface Category {
  label: string;
  value: string;
  icon: React.ReactNode;
}

export interface ChartEditorAddRowSelectProps {
  size?: SelectSize;
  width?: string;
  zIndex?: 'high' | 'highest';
  selectWidth?: number;
  value: string;
  onChange: (value: MetricSchema2Simple, valueSelected: string | null) => void;
  handleFormulaCategorySelected?: () => void;
  isComplexCase?: boolean;
  placement?: SelectPlacement;
  includeFormula?: boolean;
  includeFixedNumber?: boolean;
  includeRows?: boolean;
  includeBrackets?: boolean;
  placeholder?: string;
}

function ChartEditorAddRowSelect({
  placement = 'bottomStart',
  size = 'small',
  width = '100%',
  selectWidth,
  value,
  includeFormula = true,
  includeFixedNumber = true,
  includeRows = false,
  includeBrackets = true,
  onChange,
  handleFormulaCategorySelected,
  zIndex = 'high',
  isComplexCase = false,
  placeholder = '',
}: PropsWithChildren<ChartEditorAddRowSelectProps>) {
  const selectHeight = 300;

  // filtering custom metrics that are not used
  const [showSelect, setShowSelect] = useState(false);
  const {
    containerCallbackRef,
    containerRect,
    elementRef: dropDownRef,
    elementCallbackRef: dropDownCallbackRef,
    elementDimensions: selectDimensions,
  } = usePortalPositioning(showSelect, setShowSelect);
  const normalizedPickerOptions = useChartMetricsOptions(isComplexCase);

  useOutsideAlerter(dropDownRef, () => setShowSelect(false));
  const financialConnections = [...new Set(useFinancialAccountsConnections())];
  const crmConnections = [...new Set(useCRMPipeLinesConnections())];
  let CATEGORIES: Category[] = [];

  const icons = (category: string) => {
    if (['financial', 'crm'].includes(category)) {
      const usedConnections = category === 'financial' ? financialConnections : crmConnections;
      return (
        <StyledIconsContainer iconsCount={usedConnections.length}>
          {usedConnections.map((connection, index) => {
            return (
              <StyledImage key={connection} order={index - 1} zIndex={usedConnections.length - index}>
                {iconLookup[connection]?.()}
              </StyledImage>
            );
          })}
        </StyledIconsContainer>
      );
    }

    return iconLookup[category]?.();
  };

  if (financialConnections.length) {
    CATEGORIES.push({
      label: 'Accounting',
      value: 'financial',
      icon: icons('financial'),
    });
  }
  if (crmConnections.length) {
    CATEGORIES.push({
      label: 'CRM',
      value: 'crm',
      icon: icons('crm'),
    });
  }

  CATEGORIES.push({
    label: 'ScaleXP',
    value: 'scalexp',
    icon: <img width={24} src={` /images/logos/scalexp-icon.svg`} alt="scalexp" />,
  });

  if (includeFormula) {
    CATEGORIES.push({
      label: 'Formula',
      value: 'custom',
      icon: <Icon size={6} name="calculate" color={theme.palette.granite} />,
    });
  }
  if (includeFixedNumber) {
    CATEGORIES.push({
      label: 'Fixed Number',
      value: 'constant',
      icon: <img width={24} src={` /images/logos/number.svg`} alt="number" />,
    });
  }
  if (includeRows) {
    CATEGORIES.push({
      label: 'Row',
      value: 'row',
      icon: <Icon size={6} name="toc" color={theme.palette.granite} />,
    });
  }
  if (includeBrackets) {
    CATEGORIES.push({
      label: 'Brackets',
      value: 'custom',
      icon: <Typography>()</Typography>,
    });
  }

  const menuSearchInputRef = useRef<HTMLInputElement>(null);

  const [search, setSearch] = useState('');
  const [selectedCategory, setSelectedCategory] = useState<string | null>(null);

  useEffect(() => {
    if (selectedCategory) {
      window.scrollTo(0, document.body.scrollHeight);
    }
  }, [selectedCategory]);
  const handleCategoryClick = (category: string) => {
    if ((category === 'constant' || category === 'custom') && handleFormulaCategorySelected) {
      setShowSelect(false);
      handleFormulaCategorySelected();
    } else {
      menuSearchInputRef.current?.focus();
      setSelectedCategory(category);
    }
  };

  const handleItemClick = (itemValue: string) => {
    handleSelectSampleMetricSchema(itemValue);
    setShowSelect(false);
  };

  const handleSearchChange = (term: string) => {
    // clear category on search
    if (selectedCategory) {
      setSelectedCategory(null);
    }

    setSearch(term);
  };

  const handleSelectSampleMetricSchema = (newValue: string | null) => {
    if (!newValue) {
      return;
    }
    const [type, newId, secondId] = newValue.split(':');
    let newSimpleMetricSchemaDataSource: MetricSchema2DataSource;
    if (type === 'pt') {
      newSimpleMetricSchemaDataSource = {
        operator: 'sales',
        metricId: `${newId}_IN_PROGRESS_ACV` as SalesMetricIds,
      };
    } else if (type === 'nm') {
      newSimpleMetricSchemaDataSource = {
        operator: 'native',
        metricId: newId,
        isCashflow: false,
      };
    } else if (type === 'dm') {
      newSimpleMetricSchemaDataSource = {
        operator: 'derived',
        metricId: newId,
      };
    } else if (type === 'sm') {
      newSimpleMetricSchemaDataSource = {
        operator: 'sales',
        metricId: newId as SalesMetricIds,
      };
    } else if (type === 'ir') {
      newSimpleMetricSchemaDataSource = {
        operator: 'invoiced-revenue',
        metricId: newId as InvoicedRevenueMetricId,
      };
    } else if (type === 'pm') {
      newSimpleMetricSchemaDataSource = {
        operator: 'entity',
        entity: 'pipeline',
        pipelineId: newId,
        pipelineMetricId: 'metric_deals_won',
      };
    } else if (type === 'ps' && secondId) {
      newSimpleMetricSchemaDataSource = {
        operator: 'entity',
        entity: 'stage',
        pipelineId: secondId,
        stageId: newId,
        stageMetricId: 'metric_deals',
      };
    } else if (type === 'account') {
      newSimpleMetricSchemaDataSource = {
        operator: 'entity',
        entity: 'account',
        accountId: parseInt(newId),
        isCashflow: false,
      };
    } else if (type === 'group') {
      newSimpleMetricSchemaDataSource = {
        operator: 'entity',
        entity: 'group',
        groupId: parseInt(newId),
        isCashflow: false,
      };
    } else {
      throw new Error('Unknown type');
    }

    const newSimpleMetricSchema: MetricSchema2Simple = {
      schemaType: 'simple',
      nodes: [
        {
          operator: 'add',
          data: newSimpleMetricSchemaDataSource,
        },
      ],
      decimals: 0,
    };

    onChange(newSimpleMetricSchema, newValue);
  };

  // filter by category
  const dataFilteredByCategory = useMemo(() => filterByCategory(normalizedPickerOptions, selectedCategory), [
    normalizedPickerOptions,
    selectedCategory,
  ]);
  // search values
  const searchedValues = dataFilteredByCategory.filter(item => item.label.toLowerCase().includes(search.toLowerCase()));
  // group values
  const grouped = searchedValues.reduce(
    (group, item) => {
      const category = item.group;
      if (!category) {
        return group;
      }

      group[category] = group[category] ?? [];
      group[category].push(item);
      return group;
    },
    {} as {
      [group: string]: FormulaPickerOption[];
    },
  );

  useEffect(() => {
    if (!showSelect) {
      setSelectedCategory(null);
      setSearch('');
    }
  }, [showSelect]);

  const iconSize = size === 'small' ? 4.5 : size === 'medium' ? 5 : size === 'large' ? 5.5 : size === 'xlarge' ? 6 : 4;
  const selectedOption = normalizedPickerOptions.find(option => option.value === value)!;
  const icon =
    selectedOption?.source_id && iconLookup[selectedOption?.source_id] ? iconLookup[selectedOption.source_id]() : null;

  return (
    <StyledContainer ref={containerCallbackRef} width={width}>
      <Tooltip content={selectedOption?.label || ''}>
        <StyledValue size={size} onClick={() => setShowSelect(!showSelect)} showSelect={showSelect}>
          <Row vAlign="center" width="94%">
            {icon && icon}
            {selectedOption ? <span>{selectedOption.label}</span> : 'Archived Metric'}
          </Row>
          <Icon name="expand_more" size={iconSize} color={colors.blue[600]} className="expand-icon" />
        </StyledValue>
      </Tooltip>

      {showSelect &&
        createPortal(
          <StyledSelect
            size={size}
            placeholder={placeholder}
            ref={dropDownCallbackRef}
            customWidth={selectedCategory ? 'maxOptionWidth' : selectWidth}
            height={selectHeight}
            placement={placement}
            container={containerRect}
            select={selectDimensions}
            containerWidth={width}
            zIndex={zIndex}
          >
            <StyledSearchInputContainer>
              <SearchInput
                autoFocus
                width="100%"
                ref={menuSearchInputRef}
                value={search}
                setValue={handleSearchChange}
                placeholder="Type to search"
                onClick={() => setShowSelect(true)}
                iconPosition="start"
              />
              <Icon name="expand_less" size={6} color={colors.blue[600]} className="expand-icon" />
            </StyledSearchInputContainer>
            {/* initial state no selected category and no search */}
            {!selectedCategory && !search && (
              <StyledCategoriesContainer>
                {CATEGORIES.map(category => (
                  <StyledCategory onClick={() => handleCategoryClick(category.value)}>
                    {category.icon}
                    <Typography color="secondary">{category.label}</Typography>
                  </StyledCategory>
                ))}
              </StyledCategoriesContainer>
            )}
            {/* selected category items */}
            {selectedCategory && (
              <StyledCategoryTitleContainer>
                <Icon
                  clickable
                  name="cancel"
                  size={4}
                  color={colors.granite}
                  rounded
                  onClick={() => handleCategoryClick('')}
                />
                <Row spacing="tiny">
                  {icons(selectedCategory)}
                  <Typography>{CATEGORIES.find(category => category.value === selectedCategory)?.label}</Typography>
                </Row>
              </StyledCategoryTitleContainer>
            )}
            {searchedValues.length === 0 && <StyledMenuItem>No results found</StyledMenuItem>}
            {(search || selectedCategory) && (
              <>
                {Object.entries(grouped).map(([groupName, items]) => (
                  <>
                    {/* an exception for row category */}
                    {!(selectedCategory === 'row' && groupName === 'Row') && (
                      <StyledGroup key={groupName}>{groupName.toUpperCase()}</StyledGroup>
                    )}
                    {items.map(item => {
                      return (
                        <StyledMenuItem
                          key={`${item.label}${item.value}`}
                          onClick={() => handleItemClick(item.value)}
                          selected={value === item.value}
                        >
                          <Row vAlign="center">
                            {item.source_id && icons(item.source_id)}
                            {item.label}
                          </Row>
                        </StyledMenuItem>
                      );
                    })}
                  </>
                ))}
              </>
            )}
          </StyledSelect>,
          document.body,
        )}
    </StyledContainer>
  );
}
export default ChartEditorAddRowSelect;
