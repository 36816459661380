import { FC, useEffect } from 'react';
import Loader from 'scalexp/components/atoms/Loader';
import useLogoutAction from 'scalexp/pages/logout/useLogoutAction';

const Logout: FC = () => {
  const logoutAction = useLogoutAction();
  useEffect(() => {
    logoutAction();
  }, []);

  return <Loader center vertical content="Logging out..." />;
};

export default Logout;
