import * as am4charts from '@amcharts/amcharts4/charts';
import * as am4core from '@amcharts/amcharts4/core';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import Loader from '../../../components/atoms/Loader';
import { useChartDataType } from '../../../components/contexts/NewEditChartContext/useChartDataType';
import Row from '../../../components/layout/Row';
import { PieChart } from '../ChartCard/types';
import ChartLegend from '../ChartLegend';
import ChartTitle from '../ChartTitle';
import { configureBulletLabel } from '../WaterfallChart/helpers';
import usePieChartConfig, { PieChartConfig } from './usePieChartConfig';

export type PieChartProps = {
  config: PieChart;
  parentCallback?: (chart?: am4charts.PieChart) => void;
  rightContent?: React.ReactNode;
  headerConfig?: React.ReactNode;
};

export const StyledPieChartContainer = styled.div`
  border-radius: 5px;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  background: white;
`;
export const StyledFigureContainer = styled.div`
  height: 100%;
  width: 100%;
  background: white;
  flex-grow: 1;
`;

const getPieChartInstance = (config: PieChartConfig, element: HTMLElement, chartDataType: string) => {
  const chart: am4charts.PieChart = am4core.createFromConfig(config, element, am4charts.PieChart) as am4charts.PieChart;
  chart.fontSize = 12;

  chart.legend.itemContainers.template.tooltipText = '{category}';
  try {
    chart.paddingBottom = 20;
    chart.paddingBottom = 0;
    chart.marginBottom = 0;
    const title = chart?.titles?.getIndex(0);
    if (title) {
      title.hidden = true;
      title.dispose();
    }
    const legend = chart.legend;
    if (legend) {
      legend.maxHeight = 0;
      legend.hidden = true;
    }
  } catch (error) {}
  switch (chartDataType.toLowerCase()) {
    case 'numerical':
      chart.numberFormatter.numberFormat = '#';
      break;
    case 'percentage':
      chart.numberFormatter.numberFormat = "#.## '%'";
      break;
    case 'monetary':
      chart.numberFormatter.numberFormat = '#,###';
      break;
    default:
      break;
  }

  // Add labels to pie chart slices
  if (config.show_numbers) {
    chart.series.each(series => {
      if (series instanceof am4charts.PieSeries) {
        const labelBullet = series.bullets.push(new am4charts.LabelBullet());

        configureBulletLabel(labelBullet, '{value}', series.stroke, series.stroke, true);
        labelBullet.label.dy = -20;
      }
    });
  }

  return chart;
};

const PieChartComponent: React.FC<PieChartProps> = ({ config, parentCallback, rightContent, headerConfig }) => {
  const pieChartConfig = usePieChartConfig(config);
  const { chartDataType } = useChartDataType(config);

  const [chartState, setChartState] = useState<am4charts.PieChart | undefined>(undefined);
  const chartRefElement = useRef<HTMLDivElement>(null);
  const [chartReady, setChartReady] = useState(false);
  const amChartInstanceRef = useRef<am4charts.PieChart | null>(null);
  useEffect(() => {
    if (!pieChartConfig.value || chartReady) {
      return;
    }
    setChartReady(true);
    const cRef = chartRefElement.current;
    setTimeout(() => {
      const chart = getPieChartInstance(pieChartConfig.value!, cRef!, chartDataType);
      amChartInstanceRef.current = chart;
      // The toolbar is not visible on the first render because the chart object is empty. So we add event to update the state and show the toolbar.
      chart.events.once('appeared', () => {
        setChartState(chart);
      });
      parentCallback?.(chart);
    }, 0);
  }, [pieChartConfig]);

  useEffect(() => {
    return () => {
      let chart = amChartInstanceRef.current;
      if (chart) {
        chart.dispose();
      }
    };
  }, []);

  return (
    <StyledPieChartContainer className="chart-card">
      {!chartReady && <Loader center content="Loading..." />}
      <ChartTitle title={config.name} rightContent={rightContent} headerConfig={headerConfig} />
      <ChartLegend hideZeroValues chart={chartState} />

      {pieChartConfig.value?.isEmpty ? (
        <Row height="100%" width="100%" vAlign="center" hAlign="center">
          No data available
        </Row>
      ) : (
        <StyledFigureContainer ref={chartRefElement} role="figure" aria-label={config.name} />
      )}
    </StyledPieChartContainer>
  );
};

export default PieChartComponent;
