import capitalize from 'lodash/capitalize';
import startCase from 'lodash/startCase';
import useGetAccountingAndSalesSystem from 'scalexp/features/connections/ConnectionsContext/useGetAccountingAndSalesSystem';

type GetTrackingCodesLabelOptions = {
  format?: 'capitalize' | 'upper_case' | 'lower_case' | 'title_case';
  singular?: boolean;
};

const useGetTrackingCodesLabel = (
  option: GetTrackingCodesLabelOptions | undefined = { format: 'title_case', singular: false },
) => {
  const { singular, format } = option;
  const { accountingSystem, isLoading } = useGetAccountingAndSalesSystem();

  if (isLoading) {
    return '';
  }
  const baseLabel = accountingSystem === 'Xero' ? 'tracking code' : 'class';

  // Correct pluralization for "class" (classes instead of classs)
  const trackingCodeLabel = singular ? baseLabel : baseLabel === 'class' ? 'classes' : `${baseLabel}s`;

  let formattedLabel;
  switch (format) {
    case 'capitalize':
      formattedLabel = capitalize(trackingCodeLabel);
      break;
    case 'upper_case':
      formattedLabel = trackingCodeLabel.toUpperCase();
      break;
    case 'lower_case':
      formattedLabel = trackingCodeLabel.toLowerCase();
      break;
    case 'title_case':
      formattedLabel = startCase(trackingCodeLabel);
      break;
    default:
      formattedLabel = trackingCodeLabel;
  }

  return formattedLabel;
};

export default useGetTrackingCodesLabel;
