import { Organisation } from 'scalexp/service';
import { getAccountDisplayName } from 'scalexp/store/state/accounts/hooks';
import { Account, AccountsVS } from 'scalexp/store/state/accounts/types';

export type AccountEntries<ReturnData = any> = [accountId: string | number, accountInfo: ReturnData][];

const compareAccounts = (firstAccount: Account, secondAccount: Account, organisation: Organisation) => {
  if (firstAccount.code && secondAccount.code) {
    return parseInt(firstAccount.code) - parseInt(secondAccount.code);
  }
  if (firstAccount.code) {
    return -1;
  }
  if (secondAccount.code) {
    return 1;
  }

  const firstAccountName = getAccountDisplayName(firstAccount, organisation);
  const secondAccountName = getAccountDisplayName(secondAccount, organisation);

  return firstAccountName.localeCompare(secondAccountName);
};

export function sortAccountsEntries<ReturnData = any>(
  entries: AccountEntries<ReturnData>,
  accountsVS: AccountsVS,
  organisation: Organisation,
) {
  return entries.sort(([firstId], [secondId]) =>
    compareAccounts(accountsVS.value?.[Number(firstId)]!, accountsVS.value?.[Number(secondId)]!, organisation),
  );
}

export function sortAccounts(accounts: Account[], organisation: Organisation) {
  return accounts.sort((firstAccount, secondAccount) => compareAccounts(firstAccount, secondAccount, organisation));
}

export const showNativeMetric = (nativeMetricId: string, nativeMetricName: string | undefined) => {
  return !nativeMetricId.includes('CUSTOM_NATIVE_METRIC') || !nativeMetricName?.includes('Custom Metric ');
};
