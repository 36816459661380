import React, { PropsWithChildren, useState } from 'react';
import { useParams } from 'react-router-dom';
import Loader from 'scalexp/components/atoms/Loader';

import { useDashboard } from '../../../store/state/newDashboards/hooks';
import { Dashboard } from '../../../store/state/newDashboards/types';
import { MetricDetails } from '../../organisms/MetricSchemaChart/types';
import useActiveOrganisationId from '../OrganisationContext/useActiveOrganisationId';

interface PathParameters {
  chartSetId: string;
}

export interface DashboardContextProps {}

const emptyDashboard: Dashboard = {
  id: -1,
  name: 'New Dashboard',
  currency: null,
  cards: [],
  charts: [],
  pinned: false,
};

export const DashboardContext = React.createContext<{
  COLS_NUMBER: number;
  ROWS_NUMBER: number;
  columnWidth: number;
  setColumnWidth: (columnWidth: number) => void;
  chartSetId: number | null;
  dashboard: Dashboard;
  isEditMode: boolean;
  setIsEditMode: (isEditMode: boolean) => void;
  selectedDPMetrics: MetricDetails[] | null;
  setSelectedDPMetric: (selectedDPMetrics: MetricDetails[] | null) => void;
}>(undefined!);

export const DashboardProvider: React.FC<PropsWithChildren<DashboardContextProps>> = ({ children }) => {
  // for reseting the dashboard on cancel without hiting the backend
  const { chartSetId: chartSetIdString } = useParams<PathParameters>();
  const organisationId = useActiveOrganisationId();
  const chartSetId = Number.parseInt(chartSetIdString);
  const dashboardVS = useDashboard(organisationId, chartSetId);

  const [columnWidth, setColumnWidth] = useState(0);
  const [isEditMode, setIsEditMode] = useState(false);

  let [selectedDPMetrics, setSelectedDPMetric] = useState<MetricDetails[] | null>(null);
  if (chartSetId && dashboardVS.status !== 'success') {
    return <Loader center vertical />;
  }
  const dashboard = dashboardVS.value || emptyDashboard;
  const COLS_NUMBER = 4;
  const ROWS_NUMBER =
    (dashboard?.charts ?? []).reduce((max, card) => Math.max(max, Math.ceil(card.y) + Math.ceil(card.h)), 0) + 6;
  return (
    <DashboardContext.Provider
      value={{
        COLS_NUMBER,
        ROWS_NUMBER,
        columnWidth,
        setColumnWidth,
        chartSetId: chartSetId || null,
        dashboard,
        isEditMode,
        setIsEditMode,
        selectedDPMetrics,
        setSelectedDPMetric,
      }}
    >
      {children}
    </DashboardContext.Provider>
  );
};

export const useDashboardContext = () => {
  const value = React.useContext(DashboardContext);

  if (!value) {
    throw new Error('DashboardProvider not found!');
  }

  return value;
};
