import { addLicense as addAmChartLicense } from '@amcharts/amcharts4/core';
// NOTE: Disabling this for now as it causes the hide/show series to not work on some charts.
import * as am4core from '@amcharts/amcharts4/core';
import { CaptureConsole as CaptureConsoleIntegration } from '@sentry/integrations';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import isBetween from 'dayjs/plugin/isBetween';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import minMax from 'dayjs/plugin/minMax';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import relativeTime from 'dayjs/plugin/relativeTime';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { useEffect } from 'react';
import { Route } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { QueryParamProvider } from 'use-query-params';

import './App.css';
// This is from the django rendered views
import Routes, { ProtectedAppProvider } from './Router';
import ScalexpError from './components/organisms/ScalexpError';
import { SENTRY_SDN, SENTRY_RELEASE, ENV } from './config';
import GlobalStyles from './globalStyles';
import mapDateFormatToDayjsFormat from './pages/settings/general/mapDateFormatToDayjsFormat';
import useUserSettings from './pages/settings/general/useUserSettings';
import './rsuite.css';
import { theme } from './theme';

dayjs.extend(minMax);
dayjs.extend(isBetween);
dayjs.extend(relativeTime);
dayjs.extend(customParseFormat);
dayjs.extend(quarterOfYear);
dayjs.extend(timezone);
dayjs.extend(utc);
dayjs.extend(LocalizedFormat);

am4core.options.queue = true;
// am4core.options.onlyShowOnViewport = true;

Sentry.init({
  dsn: SENTRY_SDN,
  release: SENTRY_RELEASE,
  integrations: [new BrowserTracing(), new CaptureConsoleIntegration({ levels: ['error'] })],
  tracesSampleRate: 1.0,
  beforeSend(event) {
    // only capture the custom console error message (for undefinedOrg localStorage)
    if (event.logger === 'console' && !event.message?.includes('log_caller_func=')) {
      return null;
    }
    return event;
  },
});

document.title = ENV === 'PROD' ? 'ScaleXP' : `ScaleXP - ${ENV}`;

function App() {
  addAmChartLicense('CH257609808');

  const { data } = useUserSettings();
  const dateFormat = data?.date_format || 'DMY';

  useEffect(() => {
    dayjs.extend((option, dayjsClass, dayjsFactory) => {
      const oldFormat = dayjsClass.prototype.format;

      dayjsClass.prototype.format = function (formatString) {
        return oldFormat.bind(this)(formatString ?? mapDateFormatToDayjsFormat(dateFormat));
      };
    });
  }, [dateFormat]);
  return (
    <div className="App" style={{ height: '100%', width: '100%' }}>
      <ThemeProvider theme={theme}>
        {/* @ts-ignore */}
        <QueryParamProvider ReactRouterRoute={Route}>
          <Sentry.ErrorBoundary
            onError={(error: Error) => {
              if (ENV === 'LOCAL') {
                console.error(error);
              }
            }}
            fallback={
              <ProtectedAppProvider>
                <ScalexpError />
              </ProtectedAppProvider>
            }
          >
            <Routes />
            <GlobalStyles />
          </Sentry.ErrorBoundary>
        </QueryParamProvider>
      </ThemeProvider>
    </div>
  );
}

export default App;
