import { useAuth0 } from '@auth0/auth0-react';
import posthog from 'posthog-js';
import React, { useEffect } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import { BLOCKED_PAGE_PATH } from 'scalexp/constants';
import Logout from 'scalexp/pages/logout/Logout';

import Loader from './components/atoms/Loader';
import Message from './components/atoms/Message';
import { userContext } from './components/contexts/DeprecatedUserContext';
import { OnboardingJourneysProvider } from './components/contexts/OnboardingJourneys';
import { OrganisationProvider } from './components/contexts/OrganisationContext';
import { TrialInfoProvider } from './components/contexts/TrialContext';
import { UserProvider } from './components/contexts/UserContext';
import MainLayout from './components/organisms/MainLayout';
import { ConnectionsProvider } from './features/connections/ConnectionsContext';
import { SyncContextProvider } from './features/connections/sync-context/SyncContext';
import DashboardEditor from './features/dashboard-editor';
import Auth0Redirect from './pages/auth0';
import SwitchOrganisationHandler from './pages/auth0/switchOrganisationHandler';
import ForgotPasswordPage from './pages/auth/forgotPassword';
import OrganisationInviteRedirect from './pages/auth/organisationInviteRedirect';
import ResetPasswordPage from './pages/auth/resetPassword';
import SignInPage from './pages/auth/signIn';
import SignUpPage from './pages/auth/signUp';
import XeroSignIn from './pages/auth/xeroSignIn';
import ChartsEditorPage from './pages/chartsEditor';
import { AcceptInvitationPage } from './pages/invitations';
import ConnectionsPage from './pages/onboarding/Connections';
import OnboardingPage from './pages/onboarding/Onboarding';
import { useSelf } from './service';
import { useUserHasResourcePermission } from './service/permissions';
import { clearSession } from './utils/auth';
import { lazyWithRetry } from './utils/lazyWithRetry';

// lazy loaded routes
const LazyOauthPage = lazyWithRetry(() => import('./pages/oauth'));
const LazyHomePage = lazyWithRetry(() => import('./features/home/Home'));
const LazyUserSettings = lazyWithRetry(() => import('./pages/settings/general/UserSettings'));
const LazyDashboardsWrapper = lazyWithRetry(() => import('./components/organisms/DashboardsWrapper'));
const LazyReportEditor = lazyWithRetry(() => import('./features/report-editor/ReportEditor'));
const LazyColumnsPresets = lazyWithRetry(() => import('./features/column-editor/ColumnsPresets'));
const LazyCustomersRoutes = lazyWithRetry(() => import('./components/organisms/CustomersRoutes'));
const LazyAccrualsRoutes = lazyWithRetry(() => import('./components/organisms/AccrualsRoutes'));
const LazyNewPresentationsPage = lazyWithRetry(() => import('./features/presentations'));
const LazyPresentationEditorPage = lazyWithRetry(() => import('./features/presentations/presentation-editor'));
const LazyReportsWrapper = lazyWithRetry(() => import('./features/report/ReportsWrapper'));
const LazyBlockedPage = lazyWithRetry(() => import('./pages/blocked'));
const LazyJournals = lazyWithRetry(() => import('./pages/Journals'));

const LazyNotFoundPage = lazyWithRetry(() => import('./pages/NotFoundPage'));

// TODO: WithUser should be merged with the UserProvider
export const WithUser: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
  const { data: user, error: userError } = useSelf();
  const { logout: auth0Logout } = useAuth0();
  const location = useLocation();
  const [page, redirectUrl] = useUserHasResourcePermission();
  if (userError) {
    // check if the jwt_token has invalid organisation linked
    try {
      // @ts-ignore
      if (userError.status === 404) {
        // @ts-ignore
        const errorResponse = JSON.parse(userError.info);
        if (errorResponse.code === 'jwt_org_id_invalid') {
          clearSession();
          auth0Logout();
          return null;
        }
      }
    } catch (e) {}
    return <Message description="Failed to load user" />;
  }

  if (!user || !location) {
    return <Loader vertical backdrop content="loading" />;
  }

  // new user with no organisations should be redirected to onboarding
  if (
    !location.pathname.startsWith('/onboarding') &&
    user.available_organisations &&
    user.available_organisations.length === 0
  ) {
    window.location.href = '/onboarding';
    return null;
  }

  if (page && redirectUrl && location.pathname === '/') {
    if (!location.pathname.startsWith(page)) {
      window.location.href = redirectUrl;
    }
  }

  return <userContext.Provider value={{ user }}>{children}</userContext.Provider>;
};

const PUBLIC_ROUTES = [
  {
    exact: true,
    path: '/reset-password/:uidb64/:token',
    component: ResetPasswordPage,
  },
  {
    exact: false,
    path: '/invitations/:invitationId',
    component: AcceptInvitationPage,
  },
  {
    exact: true,
    path: '/forgot-password',
    component: ForgotPasswordPage,
  },
  {
    exact: true,
    path: '/signin',
    component: SignInPage,
  },
  {
    exact: true,
    path: '/organisation-membership-redirect',
    component: OrganisationInviteRedirect,
  },
  {
    exact: true,
    path: '/start-trial',
    component: SignUpPage,
  },
  {
    exact: true,
    path: '/login-with-xero',
    component: XeroSignIn,
  },
];

const PROTECTED_ROUTES = [
  {
    exact: false,
    path: '/oauth',
    component: LazyOauthPage,
  },
  {
    exact: true,
    path: '/',
    component: LazyHomePage,
  },
  {
    exact: false,
    path: '/settings/',
    component: LazyUserSettings,
  },
  {
    exact: true,
    path: '/dashboards/:chartSetId',
    component: LazyDashboardsWrapper,
  },
  {
    exact: true,
    path: '/dashboards',
    component: LazyDashboardsWrapper,
  },
  {
    exact: true,
    path: '/charts-editor/:chartId/edit',
    component: ChartsEditorPage,
  },
  {
    exact: true,
    path: '/dashboard-editor/new',
    component: DashboardEditor,
  },
  {
    exact: true,
    path: '/reports/:organisationId/:reportId/edit',
    component: LazyReportEditor,
  },
  {
    exact: true,
    path: '/reports/:organisationId/:reportId/edit-columns',
    component: LazyColumnsPresets,
  },
  {
    exact: false,
    path: '/analysis',
    component: LazyCustomersRoutes,
  },

  {
    exact: false,
    path: '/accruals',
    component: LazyAccrualsRoutes,
  },
  {
    exact: true,
    path: '/presentations',
    component: LazyNewPresentationsPage,
  },
  {
    exact: true,
    path: '/presentations/:presentationId/edit',
    component: LazyPresentationEditorPage,
  },
  {
    exact: true,
    path: '/reports/:reportId',
    component: LazyReportsWrapper,
  },
  {
    exact: true,
    path: '/reports',
    component: LazyReportsWrapper,
  },
  {
    exact: false,
    path: '/journals',
    component: LazyJournals,
  },
  {
    exact: true,
    path: BLOCKED_PAGE_PATH,
    component: LazyBlockedPage,
  },
];

const PROTECTED_ROUTES_WITHOUT_ORGANISATION = [
  {
    exact: false,
    path: '/onboarding',
    component: OnboardingPage,
  },
  {
    exact: true,
    path: '/auth0-callback',
    component: Auth0Redirect,
  },
  {
    exact: true,
    path: '/switch-organisation-handler',
    component: SwitchOrganisationHandler,
  },
  {
    exact: true,
    path: '/logout',
    component: Logout,
  },
];

const PROTECTED_ROUTES_WITH_ORGANISATION_AND_ONBOARDING_NAVIGATION_BAR = [
  {
    exact: false,
    path: '/connections',
    component: ConnectionsPage,
  },
];

// Rename to ProtectedAppProvider
export const ProtectedAppProvider: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  return (
    <WithUser>
      <UserProvider>
        <OrganisationProvider>
          <ConnectionsProvider>
            <OnboardingJourneysProvider>
              <SyncContextProvider>
                <TrialInfoProvider>
                  <MainLayout>{children}</MainLayout>
                </TrialInfoProvider>
              </SyncContextProvider>
            </OnboardingJourneysProvider>
          </ConnectionsProvider>
        </OrganisationProvider>
      </UserProvider>
    </WithUser>
  );
};

const Router: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    if (pathname) {
      let url = window.origin + pathname;
      posthog.capture('$pageview', {
        $current_url: url,
      });
    }
  }, [pathname]);

  return (
    // @ts-ignore
    <Switch>
      {/* Public routes can be rendered as is */}
      {PUBLIC_ROUTES.map(({ component: RouteComponent, ...route }, i) => (
        // @ts-ignore
        <Route key={i} {...route}>
          <React.Suspense fallback={<Loader center vertical />}>
            <RouteComponent />
          </React.Suspense>
        </Route>
      ))}

      {/* Protected routes without organisation */}
      {PROTECTED_ROUTES_WITHOUT_ORGANISATION.map(({ component: RouteComponent, ...route }, i) => (
        // @ts-ignore
        <Route key={i} {...route}>
          <WithUser>
            <UserProvider>
              <RouteComponent />
            </UserProvider>
          </WithUser>
        </Route>
      ))}

      {/* Protected routes with organisation and onboarding navigation bar */}
      {PROTECTED_ROUTES_WITH_ORGANISATION_AND_ONBOARDING_NAVIGATION_BAR.map(
        ({ component: RouteComponent, ...route }, i) => (
          // @ts-ignore
          <Route key={i} {...route}>
            <ProtectedAppProvider>
              <RouteComponent />
            </ProtectedAppProvider>
          </Route>
        ),
      )}

      {/* Protected routes need user and tenant contexts */}
      <ProtectedAppProvider>
        {PROTECTED_ROUTES.map(({ component: RouteComponent, ...route }, i) => (
          <Route key={i} {...route}>
            <React.Suspense fallback={<Loader center vertical />}>
              <RouteComponent />
            </React.Suspense>
          </Route>
        ))}
      </ProtectedAppProvider>

      {/* Fallback route */}
      {/* @ts-ignore */}
      <Route>
        <React.Suspense fallback={<Loader center vertical />}>
          <LazyNotFoundPage />
        </React.Suspense>
      </Route>
    </Switch>
  );
};

export default Router;
