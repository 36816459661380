import { useAuth0 } from '@auth0/auth0-react';
import posthog from 'posthog-js';
import { useHistory, useLocation } from 'react-router-dom';
import colors from 'scalexp/colors';
import { Logo } from 'scalexp/components/organisms/Header/Logo';
import TopDivider from 'scalexp/components/organisms/Header/TopDivider';
import TopMenu from 'scalexp/components/organisms/Header/TopMenu';
import useLogoutAction from 'scalexp/pages/logout/useLogoutAction';

import { useSelf } from '../../../service';
import Divider from '../../atoms/Divider';
import Icon from '../../atoms/Icon';
import Typography from '../../atoms/Typography';
import { useUpdateCurrentOrganisationContext } from '../../contexts/OrganisationContext';
import useActiveOrganisation from '../../contexts/OrganisationContext/useActiveOrganisation';
import Row from '../../layout/Row';
import AuthHeader from '../../molecules/AuthHeader';
import DropDown from '../../molecules/DropDown';
import Select from '../../molecules/Select';
import {
  HeaderNavBar,
  HelpContainer,
  LogoContainer,
  ProfileDetailsContainer,
  ProfilePicture,
  SwitchOrgContainer,
  StyledSettingsLink,
} from './Header.styles';

const Header = () => {
  const pathname = useLocation()?.pathname;
  const history = useHistory();
  const { data: user } = useSelf();
  const { user: auth0User } = useAuth0();
  const updateCurrentOrganisation = useUpdateCurrentOrganisationContext();
  const activeOrganisation = useActiveOrganisation();
  const logoutAction = useLogoutAction();

  const availableOrganisations =
    user?.available_organisations
      ?.sort((o1, o2) => o1[0].name.localeCompare(o2[0].name))
      ?.flatMap(orgList => {
        return orgList.map((org, index) => {
          const consolidatedChild = index !== 0 && orgList.length > 1;
          const isFirstAndConsolidated = index === 0 && orgList.length > 1;
          return {
            ...org,
            consolidated: isFirstAndConsolidated,
            child: consolidatedChild,
          };
        });
      }) ?? [];
  const isLoadData = pathname?.startsWith('/oauth') || pathname?.startsWith('/metrics');
  const switchOrganisation = async (id: number) => {
    if (activeOrganisation.consolidation_type !== 'SINGLE') {
      posthog.capture('Onboarding - Consolidation - Milestone 1');
    }
    const org = availableOrganisations.find(org => org.id === id);
    updateCurrentOrganisation(org);

    // @ts-ignore
    window.initializeUserGuiding();
    // @ts-ignore
    window.userGuiding.identify(user?.user_id, {
      Organisation_selection: org?.consolidation_type,
    });

    window.location.replace('/');
  };
  const isAuthPage =
    pathname?.startsWith('/signin') ||
    pathname?.startsWith('/signup') ||
    pathname?.startsWith('/reset-password') ||
    pathname?.startsWith('/forgot-password');

  const isPrintPage = pathname.endsWith('/print');

  if (!user) {
    return null;
  }

  if (isAuthPage) {
    return <AuthHeader />;
  }

  if (isPrintPage) {
    return null;
  }

  const goToSettings = () => {
    if (!isLoadData) {
      history.push('/settings/');
    } else {
      window.location.replace('/settings/');
    }
  };

  return (
    <HeaderNavBar>
      <LogoContainer>
        <Logo />
      </LogoContainer>

      <TopMenu />

      {user && (
        <Row vAlign="center" style={{ marginLeft: 'auto' }}>
          <StyledSettingsLink to="/settings">
            <Icon name="settings" color={colors.white} marginRight={0} />
            <Typography color="white">Settings</Typography>
          </StyledSettingsLink>
          <SwitchOrgContainer>
            <Select
              data={availableOrganisations.map(org => ({ ...org, label: org.name, value: org.id }))}
              value={activeOrganisation?.organisation_id}
              onChange={switchOrganisation}
              selectWidth={'maxOptionWidth'}
              selectPlaceholder="Select Organisation"
              searchable={availableOrganisations.length > 10}
              renderSelectItem={(_, org) => (
                <Row spacing="small" vAlign="center">
                  {org.child && <Icon name="chevron_right" marginRight={0} />}
                  <Typography weight="regular">{org.name}</Typography>
                </Row>
              )}
            />
          </SwitchOrgContainer>
          <TopDivider noMargin />
          <HelpContainer href="https://www.help.scalexp.com/" target="_blank">
            <Icon name="help" color="white" marginRight={0} />
            <Typography weight="semibold" color="white">
              Help
            </Typography>
          </HelpContainer>
          <DropDown placement="bottomEnd" customButton={<ProfilePicture src={auth0User?.picture} />}>
            <ProfileDetailsContainer>
              <Typography>
                {auth0User?.given_name ?? ''} {auth0User?.family_name ?? ''}
              </Typography>
              <Typography size="tiny">{user?.email ?? ''}</Typography>
            </ProfileDetailsContainer>
            <Divider />
            <DropDown.Item onClick={goToSettings} icon="manage_accounts">
              Settings
            </DropDown.Item>
            <DropDown.Item onClick={logoutAction} icon="logout">
              Logout
            </DropDown.Item>
          </DropDown>
        </Row>
      )}
    </HeaderNavBar>
  );
};

export default Header;
